import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";

const AgentReligiousDetails = ({
  religionCaste,
  registerData,
  setRegisterData,
  handleRegisterData,
  register,
  errors,
  setError,
  clearErrors,
}) => {
  const [casteList, setCasteList] = useState([]);
  const [isEditingReligious, setIsEditingReligious] = useState(true);

  //   useEffect(() => {
  //     const selectedData = religionCaste.find(
  //       (caste) => caste.religion === userData?.religion
  //     );
  //     setReligiousData({
  //       religion_id: selectedData?.id,
  //       religion: userData?.religion,
  //       caste: userData?.caste,
  //     });
  //     if (selectedData) {
  //       setCasteList([...selectedData?.caste]);
  //     }
  //   }, [userData]);

  const handleReligionChange = (event) => {
    const selectedReligion = event.target.value;
    if (selectedReligion) {
      const selectedData = religionCaste.find(
        (caste) => caste.id == selectedReligion
      );
      setRegisterData((prevValue) => ({
        ...prevValue,
        religion: selectedReligion,
        caste: "",
      }));
      setCasteList([...selectedData?.caste]);
      clearErrors("religion");
    } else {
      setRegisterData((prevValue) => ({
        ...prevValue,
        religion: "",
        caste: "",
      }));
      setCasteList([]);
      setError("religion", {
        type: "manual",
        message: "Please select a religion",
      });
    }
  };

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Religious Details</p>
      </div>

      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingReligious ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Religion<span style={{ color: "#16183E" }}>*</span>
            </div>
            :
            <div
              className={`basic-profiles  ${
                errors.religion && "border-danger"
              }`}
              style={{ flex: 1 }}
            >
              <select
                id="religion"
                name="religion"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                {...register("religion", { required: true })}
                value={registerData?.religion}
                onChange={handleReligionChange}
              >
                <option value="">Select </option>
                {religionCaste.map((religion) => (
                  <option key={religion.id} value={religion.id}>
                    {religion.religion}
                  </option>
                ))}
              </select>
              {errors.religion && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.religion.message}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Religion
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.religion}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingReligious ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Caste<span style={{ color: "#16183E" }}>*</span>
            </div>
            :
            <div
              className={`basic-profiles  ${errors.caste && "border-danger"}`}
              style={{ flex: 1 }}
            >
              <select
                id="caste"
                name="caste"
                className="SelectBoxs-searchpage-profileedit"
                value={registerData?.caste}
                {...register("caste", { required: true })}
                onChange={handleRegisterData}
              >
                <option value="">Select</option>
                {casteList.map((caste) => (
                  <option key={caste.id} value={caste.id}>
                    {caste.caste}
                  </option>
                ))}
              </select>
              {errors.caste && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.caste.message}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Caste
            </div>
            <div style={{ flex: 1 }}>
              :{""} {registerData?.caste}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default AgentReligiousDetails;
