import { axios, constants } from "../Config";

class ProfileApiFetch {
  getUserProfile(token) {
    return axios
      .get(`${constants.baseURL}user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getUserGetProfilePhoto(token) {
    return axios
      .get(`${constants.baseURL}viewphoto`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getUserPercentage(token) {
    return axios
      .get(`${constants.baseURL}getPercentage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getEditProfile(token) {
    return axios
      .get(`${constants.baseURL}view-user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateProfileDescription(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-description`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateBasicDetails(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-basic-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateReligion(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-religion-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateProfessionalDetails(payload, token) {
    console.log("ddda", payload, token);
    return axios
      .put(`${constants.baseURL}edit-professional-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateLocationDetails(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-location-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateFamilyDetails(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-family-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateHabits(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-habit-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updateHobbies(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-hobbies-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  updatePreferMatches(payload, token) {
    return axios
      .put(`${constants.baseURL}edit-partner-preference`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getProfileDetails(params, token) {
    return axios
      .get(`${constants.baseURL}view-others-profile/${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  shortListProfile(params, token) {
    return axios
      .post(
        `${constants.baseURL}shortlist-send/${params}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  likeProfile(params, token) {
    return axios
      .post(
        `${constants.baseURL}interest-send/${params}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  hideProfile(params, token) {
    return axios
      .post(
        `${constants.baseURL}hide-user/${params}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  uploadProfilePhoto(payload, token) {
    return axios
      .post(`${constants.baseURL}photo-upload`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  removeProfilePhoto(payload, token) {
    return axios
      .post(
        `${constants.baseURL}remove-photo/${payload}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getViewUploadedPhoto(token) {
    return axios
      .get(`${constants.baseURL}viewuploadPhoto`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  setAsProfilePhoto(payload, token) {
    return axios
      .post(
        `${constants.baseURL}set-profile-photo/${payload}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  getViewContact(payload, token) {
    return axios
      .post(
        `${constants.baseURL}view-contact/${payload}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  checkChat(payload, token) {
    return axios
      .post(
        `${constants.baseURL}chat/${payload}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  checkisSubscribe(token) {
    return axios
      .get(`${constants.baseURL}isSubscribe`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
}

export default new ProfileApiFetch();
