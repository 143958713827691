import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

const UpgradeModal = ({ show, handleClose, modalData, submit }) => {
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    if (modalData.features) {
      let featuresArray = Object.values(modalData?.features).map(
        (feature) => feature.description
      );
      setFeatures([...featuresArray]);
    }
  }, [modalData]);
  return (
    <MDBModal className="upgrade-modal" show={show} onHide={handleClose}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader className=" text-center">
            <MDBModalTitle className="register-sucess-title mx-auto text-center"></MDBModalTitle>
            <MDBBtn
              color="none"
              onClick={handleClose}
              className="custom-close-button"
              style={{
                background:
                  "linear-gradient(125deg, #2B99E6 29.36%, #D92199 86.14%)",
                borderRadius: "50%",
                width: "25px",
              }}
            >
              &times;
            </MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="package">
              <Row as={Col} xs={1} lg={2}>
                <Col>
                  <div className="package-left">
                    {features.map((feature, index) => (
                      <div className="package-line" key={index}>
                        <div
                          role="img"
                          aria-label="green tick"
                          className="check-image"
                        >
                          <img src="/Images/green-tick.png" alt="" />
                        </div>
                        <p>{feature}</p>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col className="background-coupon">
                  <div className="background-coupon">
                    <img
                      src={`${modalData?.img}`}
                      alt="coupon-bg"
                      className="background-coupon-image"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </MDBModalBody>
          <MDBModalFooter className="text-center changepassword-footercontent">
            <Row className="upgrade-modal-footer">
              <Col xs="8" md="9" className="upgrade-modal-footer-left">
                <p>
                  Your Voucher Code is{" "}
                  <span style={{ color: "black" }}>
                    {modalData?.voucherCode}
                  </span>
                </p>
              </Col>
              <Col xs="4" md="3">
                <Button onClick={submit}>Subscribe</Button>
              </Col>
            </Row>{" "}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default UpgradeModal;
