import axios1 from "axios";
const BASEURL = "https://matrimony.dlmbiz.com/api/"
// const BASEURL = "//localhost:5050/api/"
export const constants = {
  baseURL: BASEURL,
};
export const axios = axios1.create({
  baseURL: "",
  timeout: 30000,
  headers: { "Content-Type": "application/json",
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*', },
});
axios.interceptors.request.use(
  async function (_config) {
    return _config;
  },
  function (err) {
    console.log("error due to  no internet", err);
    return Promise.reject(err);
  }
);

axios.defaults.timeout = 30000;