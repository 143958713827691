import "../style/home.css";
import { MDBListGroup, MDBListGroupItem, MDBRipple } from "mdb-react-ui-kit";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { MDBRow, MDBCol, MDBContainer, MDBInput } from "mdb-react-ui-kit";

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import DMLApi from "../Api/UpdgradeDMLApi";

// backend codes

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import instance from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6";
import api from "../Api/StoryApi";
import "../style/home.css";
import { RxCross2 } from "react-icons/rx";

function Home() {
  const [packages, setPackages] = useState([]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //packages//
  const packagesettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //backend codes
  const location = useLocation();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    looking_for: yup.string().required("* This field is required"),
    name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Name must contain only letters and spaces")
      .required(" Please enter  name"),
    mobile: yup
      .string()
      .matches(/^\d+$/, "Please enter  phone number")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number must be provided")
      .test(
        "len",
        "Phone number must be exactly 10 digits",
        (val) => val && val.toString().length === 10
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = async (FormData) => {
    console.log("formData", FormData);
    try {
      navigate("/registration", { state: FormData });
    } catch (error) {
      console.log(error);
    }
  };
  //sucess-stores//
  const [sucessStories, setSucess] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const profilesPerPage = 8;

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        api.getStory({}).then(({ data }) => {
          if (data.status) {
            setSucess(data?.data?.success_stories);
          }
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchPlans();
    fetchUserDetails();
  }, []);

  const fetchPlans = async () => {
    try {
      let { data } = await DMLApi.fetchPlans();
      if (data.status) {
        setPackages([...data?.data]);
      }
    } catch (err) {
      console.log("Error fetching plans", err);
    }
  };

  const displayedProfiles = sucessStories
    ? sucessStories.slice(
        pageNumber * profilesPerPage,
        (pageNumber + 1) * profilesPerPage
      )
    : [];

  const pageCount = Math.ceil((sucessStories?.length || 0) / profilesPerPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  //end sucess//
  return (
    <div>
      <MDBListGroup className="lg-hid index_banner">
        <MDBListGroupItem style={{ padding: "0%" }}>
          <img
            src="../Images/rtr.jpg"
            className="img-fluid "
            alt="..."
            style={{ width: "100%" }}
          />
        </MDBListGroupItem>
      </MDBListGroup>

      <MDBContainer className="register_free bride_collect  align-items-center ">
        <form onSubmit={handleSubmit(submitForm)} action="/registration">
          <MDBRow className="mt-4  index-search ">
            <MDBCol md="3" className="indexpade-qucksearch">
              <Form.Group className="mb-3 homeregister-main" controlId="">
                <Form.Label className="formdetails">Looking for</Form.Label>
                <Form.Select
                  className="SelectBox home-register"
                  aria-label="Default select example"
                  {...register("looking_for")}
                >
                  <option value="Bride">Bride</option>
                  <option value="Groom">Groom</option>
                </Form.Select>
              </Form.Group>
            </MDBCol>
            <MDBCol md="3">
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="formdetails ">Name</Form.Label>
                <MDBInput
                  label="Enter your Name"
                  type="text"
                  className="nameInput SelectBox"
                  {...register("name")}
                />
                <label className="errormessage">
                  {errors?.name?.message ?? ""}
                </label>
              </Form.Group>
            </MDBCol>
            <MDBCol md="3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="formdetails">Mobile Number</Form.Label>
                <MDBInput
                  label="Mobile Number"
                  type="text"
                  {...register("mobile")}
                />
                <label className="errormessage">
                  {errors?.mobile?.message ?? ""}
                </label>
              </Form.Group>
            </MDBCol>
            <MDBCol md="3">
              <Button className="searchButton" type="submit">
                Register Free
              </Button>
              <p className="have-already">
                Have already an{" "}
                <Link to="/login" className="already-account">
                  account?
                </Link>
              </p>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
      <Container>
        <Row mx-auto className="homeabout-pulayakalyanam">
          <Col xs={4} className="pulayakalyam-aboutimages">
            <img src="../Images/homeabout.png" className="img-fluid"></img>
          </Col>
          <Col xs={8} className="pulayakalyam-aboutparagraph">
            <p className="pulayakalynam-aboutus">About us</p>
            <p className="pulayakalyanam-about">
              Dream Life Matrimony is a community portal offering online
              matrimonial services. Register with us for FREE to find a partner
              from your own community. Take advantage of our user friendly
              search features to find a bride or groom who matches your
              preferences. Join us and begin your happy journey today!
            </p>
          </Col>
        </Row>
      </Container>

      <MDBListGroup className=" ">
        <MDBListGroupItem style={{ padding: "0%" }}>
          <a href="/registration">
            <img
              src="../Images/about-banner.png"
              className=" "
              alt="..."
              style={{ width: "100%" }}
            />
          </a>
        </MDBListGroupItem>
      </MDBListGroup>

      <>
        {Array.isArray(displayedProfiles) && displayedProfiles.length > 0 ? (
          <>
            <h3 className="yourmatch">Success Stories</h3>
            <p className="sucess-paragrph">
              When you realize you want to spend the rest of your life with
              somebody, you want the rest of your life to start as soon as
              possible.
            </p>

            <Container className="slider-highlight">
              <Slider {...settings} className="homesuccess-slider">
                {displayedProfiles.map((story) => (
                  <MDBCol
                    size="6"
                    sm="3"
                    className="successful-couples"
                    key={story.id}
                  >
                    <MDBCard className="success-card-home">
                      <MDBRipple
                        rippleColor="light"
                        rippleTag="div"
                        className="bg-image hover-overlay"
                      >
                        <MDBCardImage
                          src={
                            story.success_status
                              ? `https://admin.ibgmatrimony.com/assets/success_stories_img/${story.photo}`
                              : `https://ibgmatrimony.com/api/uploads/${story.photo}`
                          }
                          fluid
                          alt="..."
                          className="success-couples-home"
                        />
                      </MDBRipple>
                      <MDBCardBody className="success-card-only text-center">
                        <MDBCardTitle className="success-details">
                          {story.bride_name} & {story.groom_name}
                        </MDBCardTitle>
                        <MDBCardText className="success-details-date">
                          {story.address}
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
              </Slider>
            </Container>
          </>
        ) : (
          <div></div>
        )}
      </>

      <div>
        <h3 className="yourmatch">How It Works</h3>
        <p className="life-somebody">
          When you realize you want to spend the rest of your life with
          somebody, you want the rest of your life to start as soon as possible.
        </p>

        <Container>
          <MDBRow className="mt-0 workingflow">
            <MDBCol size="6" sm="3" className="steps-background">
              <img
                src="../Images/register-howit.png"
                alt="Step 1"
                style={{ width: "30%" }}
              />
              <caption className="home-contact">Register</caption>
            </MDBCol>
            <MDBCol size="6" sm="3" className="steps-background">
              <img
                src="../Images/search-howit.png"
                alt="Step 2"
                style={{ width: "30%" }}
              />
              <caption className="home-contact">Search</caption>
            </MDBCol>
            <MDBCol size="6" sm="3" className="steps-background ">
              <img
                src="../Images/select-howit.png"
                alt="Step 3"
                style={{ width: "30%" }}
              />
              <caption className="home-contact">Select</caption>
            </MDBCol>
            <MDBCol size="6" sm="3" className="steps-background ">
              <img
                src="../Images/call-howit.png"
                alt="Step 4"
                style={{ width: "27%" }}
              />
              <caption className="home-contact">Contact</caption>
            </MDBCol>
          </MDBRow>
        </Container>
      </div>
      <div className="home-packages">
        <h3 className="yourmatch">Packages</h3>
        <p className="life-somebody-packages">
          When you realize you want to spend the rest of your life with
          somebody, you want the rest of your life to start as soon as possible.
        </p>
        <Container>
          <div
            className="list-packages"
            style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            {packages.map((plan, index) => {
              const features = Object.values(plan.features).map(
                (feature) => feature.description
              );

              return (
                <div className="package" style={{background: "#fff"}} key={plan.id}>
                  <Row>
                    <Col>
                      <div className="package-left">
                        {features.map((feature, index) => (
                          <div className="package-line" key={index}>
                            <div
                              role="img"
                              aria-label="green tick"
                              className="check-image"
                            >
                              <img
                                src="/Images/green-tick.png"
                                alt="green tick"
                              />
                            </div>
                            {feature}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col className="background-coupon">
                      <div className="background-coupon">
                        <img
                          src={plan?.image_url}
                          alt="coupon-bg"
                          className="background-coupon-image"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
      <Container className="call-enquiries">
        <div className="enquires">
          <img src="../Images/call-enquires.png"></img>
          <p className="call-enquires">
            <span className="need-assistance">Need assistance? </span>please
            call us for more queries
          </p>
          <div className="enquiries-button">
            <Button href="/" className="upgrade-submit-call" type="Call">
              Call us
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Home;
