import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../Api/Agent/AgentProfileApi";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import AgentProfileLeftDetails from "../ProfileDetails/LeftDetails/LeftDetails";
import AgentUserProfileRightSide from "./AgentUserProfileRightSide";
import Chat from "../../Chat";
import { toast } from "react-toastify";
import AgentFooter from "../AgentFooter";

const AgentUserDetails = () => {
  const token = localStorage.getItem("authToken");
  const { profileId, userId } = useParams();
  const [profileData, setProfileData] = useState({});
  const [profileImages, setProfileImages] = useState([]);
  const [profileDetails, setProfileDetails] = useState();
  const [partnerPreference, setPartnerPreference] = useState([]);
  const [moreProfiles, setMoreProfiles] = useState([])
  const [isBlocked, setIsBlocked] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  useEffect(() => {
    fetchProfileDetails();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [profileId, userId]);

  const fetchProfileDetails = () => {
    let payload = {
      profileId: profileId,
    };
    api.getAgentUserProfileDetails(userId, payload, token).then(({ data }) => {
      if (data?.status) {
        setProfileData(data?.data?.user_profile);
        setProfileDetails(data?.data?.agentUserDetails);
        setMoreProfiles(data?.data?.similar_profiles)
        if (data?.data?.images.length > 0) {
          setProfileImages(
            data?.data?.images.map((img) => ({
              profileImage: img,
            }))
          );
        } else {
          setProfileImages([])
        }
        setPartnerPreference(data?.data?.partner_preference);
      }
    });
  };
  const handleBlockUser = () => {
    let payload = {
      otherProfileId: profileData?.profile_id,
    };
    api.blockAgentProfiles(profileId, payload, token).then(({ data }) => {
      if (data.status) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpenChat(false);
        setIsBlocked(true);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Agent | Dream Life Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <AgentNavbar />
        <Container>
          <MDBRow className="profileedit-matches-all">
            {isBlocked ? (
              <div
                className="d-flex justify-content-center fs-5 text-secondary  align-items-center"
                style={{ height: "70vh" }}
              >
                <p style={{ color: "#16183E", marginTop: "1rem" }}>
                  {" "}
                  This profile was blocked
                </p>
              </div>
            ) : (
              <>
                <AgentProfileLeftDetails
                  profileData={profileData}
                  profileImages={profileImages}
                  moreProfiles={moreProfiles}
                  from="user"
                />
                <AgentUserProfileRightSide
                  profileData={profileData}
                  profileImages={profileImages}
                  partnerPreference={partnerPreference}
                  setIsBlocked={setIsBlocked}
                  profileDetails={profileDetails}
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                />
              </>
            )}
          </MDBRow>
        </Container>
      </section>
      {openChat && (
        <Chat
          chatData={{
            userId: profileData?.profile_id,
            name: profileData?.name,
            image: profileImages[0] ? profileImages[0]?.profileImage : "",
          }}
          onClose={() => setOpenChat(false)}
          from="PROFILE"
          isAgent={true}
          profileDetails={{
            profileId: profileId,
            name: profileDetails?.name,
            image: profileDetails?.image,
          }}
          onBlockUser={handleBlockUser}
        />
      )}
      <AgentFooter />
    </>
  );
};

export default AgentUserDetails;
