import React, { useEffect, useState } from "react";
import api from "../../../Api/MatchApi";
import UserCard from "../../UserCard/Index";
import AdCard from "../../AdCards/AdCard";
import ReactPaginate from "react-paginate";
import "../../../style/dropdown.css";
import Chat from "../../Chat";
import GeneralApi from "../../../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";

function LocationMatch() {
  const token = localStorage.getItem("authToken");
  const [locationMatches, setLocationMatches] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [districts, setDistricts] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [pagination, setPagination] = useState({});
  const [openLocation, setOpenLocation] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchLocationMatches();
  }, [pageDetails, selectedLocations]);

  const fetchLocationMatches = async () => {
    setLocationMatches([]);
    const locationIds = await selectedLocations.map((location) => location.id);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
      selectedDistricts: locationIds,
    };
    await api.getLocationMatches(payload, token).then(({ data }) => {
      if (data.status) {
        setDistricts(data?.data?.location_list);
        setPagination(data?.data?.pagination);
        setLocationMatches(data?.data?.locationMatches);
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
    setCurrentPage(selected);
  };

  const handleDistrictChange = (district) => {
    setOpenLocation(false);
    setCurrentPage(0);
    const findLocation = selectedLocations.find(
      (location) => location?.id === district?.id
    );
    if (!findLocation) {
      setSelectedLocations([...selectedLocations, district]);
    } else {
      const filterLocation = selectedLocations.filter(
        (location) => location?.id !== district?.id
      );
      setSelectedLocations([...filterLocation]);
    }
  };

  const hideProfile = (userId) => {
    const matches = locationMatches.filter(
      (user) => user?.profile_id !== userId
    );
    if (matches.length > 0) {
      setLocationMatches(matches);
    } else {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page !== 1 ? pageDetails?.page - 1 : 1,
      }));
    }
  };

  const handleBlockUser = () => {
    GeneralApi.blockUser(chatData?.userId, token).then(({ data }) => {
      if (data?.status) {
        hideProfile(chatData?.userId);
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setChatData({
          status: false,
          userId: "",
          name: "",
          image: "",
        });
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pictures-client">
        {districts.length > 0 && (
          <div className="full_d">
            <div className="selectplaces">
              {selectedLocations.map((location, index) => (
                <div className="placename" key={index}>
                  <h5>{location?.district}</h5>
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      handleDistrictChange(location);
                    }}
                  >
                    <img src="Images/close.svg" alt="" />
                  </button>
                </div>
              ))}
            </div>

            <div className="hero">
              <div className="button-drop-container">
                <form className="button-group">
                  <div className="drop-button-wrap">
                    <input
                      id="db1"
                      type="checkbox"
                      checked={openLocation}
                      onChange={() => setOpenLocation(!openLocation)}
                    />
                    <label htmlFor="db1" className="drop-button">
                      <img
                        style={{ width: "30px" }}
                        src="Images/dropi.svg"
                        alt=""
                      />
                    </label>
                    <div className="drop-pop">
                      <h2>Select District</h2>
                      {districts.map((district, index) => {
                        const isSelected = selectedLocations.find(
                          (location) => location.id === district.id
                        );
                        return (
                          <div className="place_d" key={index}>
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              value={district?.id}
                              onChange={() => handleDistrictChange(district)}
                              checked={isSelected ? true : false}
                            />
                            <h6 style={{ marginBottom: "0" }}>
                              {district?.district}
                            </h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {/* <div className="intrest_container mobile_intrest">
          <div className="mobleft">
            <div className="left_img">
              <div className="img_new">
                <div style={{ position: "relative" }}>
                  <img src="Images/person.png" alt="" />
                </div>
              </div>
            </div>
            <div className="right_details">
              <div className="intrest_like">
                <img src="Images/intrestlike.svg" alt="" />
                <h4>
                  <span style={{ color: "#0AB93B" }}>Accepted Profile</span> |
                  10.07.2023
                </h4>
              </div>

              <div className="name1">
                <h2>Dani Sebastine </h2>
                <img src="Images/online.png" alt="" />
                <p className="online" style={{ margin: "0" }}>
                  online
                </p>
              </div>

              <div className="details_caption">
                <p>ID000023 | Profile Created by Parent</p>
                <p>27 Yrs, 5'7", Malayalam,</p>
                <p>Hindhu / Christain, Muslim,</p>
                <p>
                  {" "}
                  B.Tech., Civil Engineer, ₹ 2 - 3 Lakhs, Irinjalakuda, Kerala
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: "10px",
              paddingTop: "10px",
            }}
            className="bottom_details"
          >
            <div>
              <p>
                {" "}
                You have accepted her interest. Would you like to communicate
                further?
              </p>
            </div>
            <div>
              <button style={{ background: "#F39200" }}>Call</button>
              <button>Message</button>
            </div>
          </div>

          <div></div>
        </div> */}

        {locationMatches?.length > 0 ? (
          locationMatches.map((match, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={match}
                hideProfile={hideProfile}
                chatData={chatData}
                setChatData={setChatData}
              />
              {index === 3 && <AdCard />}
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary align-items-center">
            <p style={{ color: "#16183E", marginTop: "1rem" }}>
              {" "}
              No data found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
              forcePage={currentPage}
            />
          </div>
        )}
      </div>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from="PROFILE"
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
}

export default LocationMatch;
