import React, { useEffect, useState } from "react";
import api from "../../../Api/MatchApi";
import UserCard from "../../UserCard/Index";
import AdCard from "../../AdCards/AdCard";
import ReactPaginate from "react-paginate";
import Chat from "../../Chat";
import GeneralApi from "../../../Api/GeneralApi";
import { ToastContainer, toast } from "react-toastify";

const All = () => {
  const token = localStorage.getItem("authToken");
  const [allMatches, setAllMatches] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchAllMatches();
  }, [pageDetails]);

  const fetchAllMatches = async () => {
    setAllMatches([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getAllMatches(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setAllMatches(data?.data?.allMatches);
      }
    });
  };

  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  const hideProfile = (userId) => {
    const newMatches = allMatches.filter((user) => user.profile_id !== userId);
    if (newMatches.length > 0) {
      setAllMatches(newMatches);
    } else {
      setPageDetails((prevValue) => ({
        ...prevValue,
        page: pageDetails?.page !== 1 ? pageDetails?.page - 1 : 1
      }))
    }
  };

  const handleBlockUser = () => {
    GeneralApi.blockUser(chatData?.userId, token).then(({ data }) => {
      if (data?.status) {
        hideProfile(chatData?.userId);
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setChatData({
          status: false,
          userId: "",
          name: "",
          image: "",
        });
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pictures-client">
        {allMatches?.length > 0 ? (
          allMatches.map((match, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={match}
                hideProfile={hideProfile}
                chatData={chatData}
                setChatData={setChatData}
              />
              {index === 3 && <AdCard />}
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#16183E", marginTop: "1rem" }}>
              {" "}
              No matches found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from="PROFILE"
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
};

export default All;
