import React, { useEffect, useState } from "react";

import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import Footer from "./Footer";
import { Button, Col, Form, Row } from "react-bootstrap";
import "../style/upgrade.css";
import Navbars from "./Navbars";
import ProfileSideBar from "./ProfileSideBar";
import InterationSection from "./Interations/InterationSection";
import { Helmet } from "react-helmet";
import UpgradeModal from "./upgrade/UpgradeModal";
import DMLApi from "../Api/UpdgradeDMLApi";
import SubscribeApi from "../Api/SubscribeApi";

function Upgrade() {
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  const [packages, setPackages] = useState([]);
  const [couponVoucher, setCouponVoucher] = useState("");
  const [isCoupon, setIsCoupon] = useState({
    status: false,
    message: "Please enter voucher code",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    voucherCode: "",
    type: "",
    amount: "",
    features: null,
    valid_till: "",
    validity_months: "",
    img: "",
  });
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({
    img_url: "",
    valid_upto: "",
    features: [],
  });
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      let { data } = await DMLApi.fetchPlans();
      if (data.status) {
        setPackages([...data?.data]);
      }
      let { data: currentPlanResponse } = await SubscribeApi.getCurrentPlan(
        token
      );
      if (currentPlanResponse.status) {
        setIsSubscribed(currentPlanResponse?.data?.isSubscribed);
        if (currentPlanResponse.data?.isSubscribed) {
          const convertToLocale = new Date(
            currentPlanResponse?.data?.expiry_date
          ).toLocaleString();
          const date = new Date(convertToLocale);

          // Format to DD-MM-YYYY
          const formattedDate = `${date
            .getDate()
            .toString()
            .padStart(2, "0")}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getFullYear()}`;

          setCurrentPlan({
            img_url: currentPlanResponse?.data?.img_url,
            features: currentPlanResponse?.data?.features,
            valid_upto: formattedDate,
          });
        }
      }
    } catch (err) {
      console.log("Error fetching plans", err);
    }
  };
  const verifyVoucherCoupon = async () => {
    if (couponVoucher) {
      setIsCoupon(false);
      setIsLoading(true);
      let { data } = await DMLApi.verifyVoucher({
        voucher_code: couponVoucher,
      });
      setIsLoading(false);
      if (data.status) {
        let validMonth = await packages.find(
          (packag) => packag.type === data?.data?.type
        );
        setModalData({
          voucherCode: data?.data?.voucher_code,
          type: data?.data?.type,
          amount: data?.data?.amount,
          features: data?.data?.features,
          valid_till: data?.data?.valid_till,
          validity_months: validMonth?.validity_months,
          img: data?.data?.voucher_img,
        });
        setIsModalOpen(true);
      } else {
        setIsCoupon({
          status: true,
          message: data.error,
        });
      }
    } else {
      setIsCoupon((prevValue) => ({
        ...prevValue,
        status: true,
      }));
    }
  };

  const applySubscribe = async () => {
    let payload = {
      voucher_code: modalData?.voucherCode,
      type: modalData?.type,
      amount: modalData?.amount,
      features: modalData?.features,
      img_url: modalData?.img,
      validity_months: modalData?.validity_months,
      view_contacts: modalData?.features?.view_contacts?.value,
      send_messages: modalData?.features?.send_messages?.value,
      interests: modalData?.features?.interests?.unlimited,
      advanced_search: modalData?.features?.advanced_search?.unlimited,
      profile_views: modalData?.features?.profile_views?.unlimited,
    };

    let { data } = await SubscribeApi.ApplyVoucher(payload, token);
    if (data.status) {
      let resPayload = {
        voucher_code: modalData?.voucherCode,
        used_by: data?.data?.user_id,
        transaction_id: data?.data?.transaction_id,
      };
      let { data: response } = await DMLApi.voucherUse(resPayload);
      console.log("response", response);
      if (response.status) {
        let confirmPayload = {
          voucher_code: couponVoucher,
          transaction_id: data?.data?.transaction_id,
        };
        let { data: successReponse } = await SubscribeApi.ConfirmVoucher(
          confirmPayload,
          token
        );
        if (successReponse.status) {
          setOpenSuccessModal(true);
          setCurrentPlan({
            img_url: modalData?.img,
            features: [
              ...Object.values(modalData?.features).map(
                (feature) => feature.description
              ),
            ],
            valid_upto: successReponse?.data?.expiry_date,
          });
          setIsModalOpen(false);
          setCouponVoucher("");
          setTimeout(() => {
            setIsSubscribed(true);
          }, 1000);
        }
      }
    } else {
      console.log("data", data);
    }
  };

  return (
    <>
      <Helmet>
        <title>Upgrade | Dream Life Matrimony</title>
      </Helmet>
      <div className="registerbody">
        <Navbars
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
        {selectedTab === "Home" ? (
          <MDBContainer className=" mt-5 ">
            <div className="registerform">
              <div className="rgtr">
                <div className="bannr">
                  <img
                    src="../Images/rtr.jpg"
                    className="img-fluid "
                    alt="..."
                    style={{ width: 100 }}
                  />
                </div>
              </div>
              <MDBContainer>
                <div className="package-section">
                  <div className="apply-section">
                    {isSubscribed && (
                      <>
                        <div className="plan-title">
                          <img src="/Images/plan-icon.png" height={30} />
                          <p>
                            Your Current Plan{" "}
                            <span>
                              (Valid untill {currentPlan?.valid_upto})
                            </span>
                          </p>
                        </div>
                        <div className="package">
                          <Row as={Col} xs={1} lg={2}>
                            <Col>
                              <div className="package-left">
                                {currentPlan?.features.map((feature, index) => (
                                  <div className="package-line" key={index}>
                                    <div
                                      role="img"
                                      aria-label="green tick"
                                      className="check-image"
                                    >
                                      <img
                                        src="/Images/green-tick.png"
                                        alt=""
                                      />
                                    </div>
                                    <p>{feature}</p>
                                  </div>
                                ))}
                              </div>
                            </Col>
                            <Col className="background-coupon">
                              <div className="background-coupon">
                                <img
                                  src={`${currentPlan?.img_url}`}
                                  alt="coupon-bg"
                                  className="background-coupon-image"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    <Row>
                      <Col md="9" className="mx-auto">
                        <Form>
                          <Form.Group as={Row}>
                            <Form.Label>Promo Code / Voucher</Form.Label>
                            <Col xs="8" md="9" className="div1">
                              <Form.Control
                                type="text"
                                name="coupn"
                                placeholder="Enter voucher code"
                                value={couponVoucher}
                                className={`${
                                  isCoupon.status && "error-input"
                                }`}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setIsCoupon((prevValue) => ({
                                      ...prevValue,
                                      status: false,
                                    }));
                                  } else {
                                    setIsCoupon((prevValue) => ({
                                      ...prevValue,
                                      status: true,
                                    }));
                                  }
                                  setCouponVoucher(e.target.value);
                                }}
                              />
                            </Col>
                            <Col xs="4" md="3" className="div1">
                              <Button onClick={verifyVoucherCoupon}>
                                {isLoading ? (
                                  "Loading..."
                                ) : isSubscribed ? (
                                  <>
                                    Upgrade
                                    <span className="cpn-btn-text">
                                      Your Plan
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Apply
                                    <span className="cpn-btn-text">
                                      Your Voucher
                                    </span>
                                  </>
                                )}
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                        {isCoupon.status && (
                          <p className="coupon-validation-err">
                            {isCoupon.message}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="searchprofile">
                    <h4>Our Packages</h4>
                    <div className="list-packages">
                      {packages.map((plan, index) => {
                        const features = Object.values(plan.features).map(
                          (feature) => feature.description
                        );

                        return (
                          <div className="package" key={plan.id}>
                            <Row>
                              <Col>
                                <div className="package-left">
                                  {features.map((feature, index) => (
                                    <div className="package-line" key={index}>
                                      <div
                                        role="img"
                                        aria-label="green tick"
                                        className="check-image"
                                      >
                                        <img
                                          src="/Images/green-tick.png"
                                          alt="green tick"
                                        />
                                      </div>
                                      {feature}
                                    </div>
                                  ))}
                                </div>
                              </Col>
                              <Col className="background-coupon">
                                <div className="background-coupon">
                                  <img
                                    src={plan?.image_url}
                                    alt="coupon-bg"
                                    className="background-coupon-image"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </MDBContainer>
            </div>
          </MDBContainer>
        ) : (
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
            <InterationSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              titleDiscription={titleDiscription}
              setTitleDiscription={setTitleDiscription}
            />
          </MDBRow>
        )}

        <Footer />
      </div>
      <UpgradeModal
        show={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setModalData({
            couponVoucher: "",
            features: [],
            img: "",
          });
        }}
        modalData={modalData}
        submit={applySubscribe}
      />
      <MDBModal
        show={openSuccessModal}
        onHide={() => setOpenSuccessModal(false)}
        centered
      >
        <MDBModalDialog>
          <MDBModalHeader
            className="submit-register text-center"
            style={{
              borderBottom: "0px",
              border: "10px",
              marginTop: "10rem",
            }}
          >
            <MDBModalTitle className="register-sucess-title mx-auto">
              <img
                src="/Images/reg-success.png"
                style={{
                  width: "25%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                }}
              />
              <br />
              <p className="reg-success-message">Successfully subscribed</p>
              <MDBBtn
                color="none"
                onClick={() => setOpenSuccessModal(false)}
                className="custom-close-buttons"
              >
                OK
              </MDBBtn>
            </MDBModalTitle>
          </MDBModalHeader>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default Upgrade;
