import React, { useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import Footer from "../Footer";
import { Container } from "react-bootstrap";
import Search from "./Search";
import Nva from "../Nva";
import { Helmet } from "react-helmet";

const SearchProfiles = () => {
  const token = localStorage.getItem("authToken");
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );

  return (
    <>
      <Helmet>
        <title>Search | Dream Life Matrimony</title>
      </Helmet>

      <section className="matches-background">
        {token ? (
          <Navbars
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
        ) : (
          <Nva />
        )}

        <Container>
          <Search
            selectedTab={selectedTab}
            titleDiscription={titleDiscription}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default SearchProfiles;
