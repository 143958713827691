import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

const AgentEditBasicDetails = ({
  registerData,
  setRegisterData,
  handleRegisterData,
  listHeights,
  listStars,
  register,
  setValue,
  errors,
}) => {
  // useEffect(() => {
  //   const today = new Date();
  //   const pastDate = new Date(today.setFullYear(today.getFullYear() - 18));
  //   const year = pastDate.getFullYear();
  //   const month = String(pastDate.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
  //   const day = String(pastDate.getDate()).padStart(2, "0"); // Ensure 2-digit day
  //   const formattedDate = `${year}-${month}-${day}`;
  //   setValue("date_birth", formattedDate);
  //   setValue("age", "18");
  //   setValue("gender", "Male");
  //   setRegisterData((prevValue) => ({
  //     ...prevValue,
  //     date_birth: formattedDate,
  //     age: "18",
  //   }));
  // }, []);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 90,
    today.getMonth(),
    today.getDate()
  );

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
    const formattedDate = `${year}-${month}-${day}`;

    const age = calculateAge(formattedDate);

    setSelectedDate(date);

    setRegisterData((prevValue) => ({
      ...prevValue,
      date_birth: formattedDate,
      age: age.toString(),
    }));
    setValue("date_birth", formattedDate);
    setValue("age", age.toString());
  };

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Basic Details</p>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Name<span style={{ color: "#16183E" }}>*</span>
          </div>
          :
          <div
            className={`basic-profiles  ${errors.name && "border-danger"}`}
            style={{ flex: 1 }}
          >
            <input
              type="text"
              className="SelectBoxs-searchpage-profileedit"
              name="name"
              value={registerData?.name}
              {...register("name", { required: true })}
              onChange={handleRegisterData}
            />
            {errors.name && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.name.message}
              </span>
            )}
          </div>
        </div>
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Gender
          </div>{" "}
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="gender"
              name="gender"
              className="SelectBoxs-searchpage-profileedit"
              value={registerData.gender}
              disabled
            >
              <option value="" disabled selected>
                Select
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Date of birth<span style={{ color: "#16183E" }}>*</span>
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <span className="datespaceonly">
              <DatePicker
                placeholderText="yyyy-mm-dd)"
                name="date_birth"
                {...register("date_birth", { required: true })}
                selected={selectedDate}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                dateFormat="yyyy-MM-dd"
                maxDate={maxDate}
                minDate={minDate}
                showYearDropdown
                yearDropdownItemNumber={90}
                value={registerData?.date_birth}
                className="SelectBoxs-searchpage-profileedit"
              />
            </span>
          </div>
        </div>
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Age
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <input
              type="text"
              name="age"
              value={registerData?.age ? `${registerData.age} Years` : ""}
              style={{ border: "none" }}
              {...register("age")}
              className="SelectBoxs-searchpage-profileedit"
              disabled
            />
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Height
          </div>{" "}
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <span className="heightpaceonly">
              <select
                id="height"
                name="height"
                {...register("height")}
                className="SelectBoxs-searchpage-profileedit-height"
                value={registerData?.height} // Initialize with basicDetails.height_id
                onChange={handleRegisterData}
              >
                <option value="">Select</option>

                {listHeights.map((height) => (
                  <option key={height?.id} value={height?.id}>
                    {`${height?.height_cm} cm / ${height?.height_feet}`}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        {/*   WEIGHT */}
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Weight in kg
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <input
              type="text"
              className="SelectBoxs-searchpage-profileedit"
              name="weight"
              {...register("weight")}
              value={registerData?.weight}
              onChange={handleRegisterData}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Marital Status
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="maritalStatus"
              name="matrial_status"
              {...register("matrial_status")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.matrial_status}
              onChange={handleRegisterData}
            >
              <option value="">Select</option>
              <option value="Never Married">Never Married</option>
              <option value="Widowed">Widowed</option>
              <option value="Divorced">Divorced</option>
              <option value="Seperated">Seperated</option>
            </select>
          </div>
        </div>

        {/* BODY TYPE */}
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Body Type
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="bodyType"
              name="body_type"
              className="SelectBoxs-searchpage-profileedit"
              {...register("body_type")}
              value={registerData?.body_type}
              onChange={handleRegisterData}
            >
              <option defaultValue="">Select</option>
              <option value="Average">Average</option>
              <option value="Slim">Slim</option>
              <option value="Athletic">Athletic</option>
              <option value="Heavy">Heavy</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Physical Status
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="physicalStatus"
              name="physical_status"
              {...register("physical_status")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.physical_status}
              onChange={handleRegisterData}
            >
              <option Value="">Select</option>
              <option value="Physically Challenged">
                Physically Challenged
              </option>
              <option value="Normal">Normal</option>
            </select>
          </div>
        </div>
        {/* COMPLEXION */}
        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Complexion
          </div>{" "}
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="complexion"
              name="complexion"
              {...register("complexion")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.complexion}
              onChange={handleRegisterData}
            >
              <option value="Fair">Fair</option>
              <option value="Wheatish">Wheatish</option>
              <option value="Dark">Dark</option>
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Star
          </div>
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="star"
              name="star"
              {...register("star")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.star}
              onChange={handleRegisterData}
            >
              <option key="" value="">
                Select
              </option>
              {listStars.map((star) => (
                <option key={star.id} value={star.id}>
                  {star.star}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="annual-income" style={{ display: "flex" }}>
          <div style={{ flex: 1 }} className="">
            Any Disability
          </div>{" "}
          :
          <div className="basic-profiles" style={{ flex: 1 }}>
            <select
              id="any_disability"
              name="any_disability"
              {...register("any_disability")}
              className="SelectBoxs-searchpage-profileedit"
              value={registerData?.any_disability}
              onChange={handleRegisterData}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default AgentEditBasicDetails;
