import { MDBCol } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import SliderInteration from "./SliderInteration";
import InterestedProfile from "./Interation/InterestedProfile/InterestedProfile";
import InterestedRecived from "./Interation/InterestedRecieved/InterestedRecived";
import AcceptedProfile from "./Interation/AcceptedProfile/AcceptedProfile";
import AcceptedbyOther from "./Interation/AcceptedbyOther/AcceptedbyOther";
import ShortlistedProfile from "./Interation/ShortlistedProfile/ShortlistedProfile";
import RejectedProfile from "./Interation/RejectedProfile/RejectedProfile";
import RejectedbyOther from "./Interation/RejectedbyOther/RejectedbyOther";
import Hide from "./Interation/Hide/Hide";
import WhoVisited from "./Interation/WhoVisited/WhoVisited";
import MyShortlist from "./Interation/MyShortlist/MyShortlist";
import Block from "./Interation/Blocked/Block";
import ContactViewed from "./Interation/ContactViewed/ContactViewed";

function InterationSection({
  selectedTab,
  setSelectedTab,
  titleDiscription,
  setTitleDiscription,
}) {
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="member-who-waches">
        <p className="member-watches">
          <span className="new-matches-only">{selectedTab}</span> -{" "}
          {titleDiscription}
        </p>
      </div>
      <SliderInteration
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      {selectedTab === "Interest Sent" && <InterestedProfile />}
      {selectedTab === "Interested Received" && <InterestedRecived />}
      {selectedTab === "Accepted Profile" && <AcceptedProfile />}
      {selectedTab === "Accepted By Other" && <AcceptedbyOther />}
      {selectedTab === "Rejected Profile" && <RejectedProfile />}
      {selectedTab === "Rejected By Other" && <RejectedbyOther />}
      {selectedTab === "Shortlisted By Other" && <ShortlistedProfile />}
      {selectedTab === "My Shortlist" && <MyShortlist />}
      {selectedTab === "Who Visited My Profile" && <WhoVisited />}
      {selectedTab === "Hide Profile" && <Hide />}
      {selectedTab === "Blocked Profile" && <Block />}
      {selectedTab === "Contact Viewed" && <ContactViewed />}
    </MDBCol>
  );
}

export default InterationSection;
