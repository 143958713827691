import React, { useEffect, useState } from "react";
import api from "../../../../../Api/Agent/AgentProfileApi";
import { useParams } from "react-router-dom";
import AgentMatchProfileCard from "../Matches/AgentMatchProfileCard";
import ReactPaginate from "react-paginate";
import Chat from "../../../../Chat";
import { toast } from "react-toastify";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { firebaseApp } from "../../../../../firebase/firebase";

const ShortlistedAgentProfileList = ({ activeTab, profileDetails }) => {
  const { profileId } = useParams();
  const firebaseDB = getFirestore(firebaseApp);
  const token = localStorage.getItem("authToken");
  const [pagination, setPagination] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
  });
  useEffect(() => {
    const element = document.getElementById("agent-profile-match");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    fetchShortlistedProfiles();
  }, [pageDetails, activeTab]);
  const fetchShortlistedProfiles = () => {
    let payload = {
      page: pageDetails?.page,
    };
    api
      .getShortlistAgentProfiles(profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setPagination(data?.data?.pagination);
          setProfiles(data?.data?.shortlist);
        }
      });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };
  const handleBlockUser = () => {
    let payload = {
      otherProfileId: chatData?.userId,
    };
    api
      .blockAgentProfiles(profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setProfiles((prevValue) =>
            prevValue.filter((prv) => prv.profile_id !== chatData?.userId)
          );
          if (profiles.length === 1 && pageDetails?.page !== 1) {
            setPageDetails((prevValue) => ({
              ...prevValue,
              page: pageDetails?.page - 1,
            }));
          }
          setChatData({
            status: false,
            userId: "",
            name: "",
            image: "",
          });
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          updateMessageInFirebase();
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const updateMessageInFirebase = async () => {
    const currentTimeInUTC = new Date().toUTCString();
    const combinedId =
      profileId > chatData?.userId
        ? `${chatData?.userId}-${profileId}`
        : `${profileId}-${chatData?.userId}`;
    await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
      status: "BLOCKED",
      time: currentTimeInUTC,
    });
  };
  return (
    <>
      <div className="member-who-waches">
        <p className="member-watches">
          <span className="new-matches-only">My Shortlist</span> - Members Who
          have Shortlisted by you!
        </p>
      </div>
      {profiles.length > 0 ? (
        profiles.map((profile, index) => (
          <AgentMatchProfileCard
            key={index}
            user={profile}
            from="accepted"
            chatData={chatData}
            setChatData={setChatData}
          />
        ))
      ) : (
        <div className="profile-editprofile">
          <div className="d-flex justify-content-center fs-5 text-secondary align-items-center h-100">
            <p style={{ color: "#16183E", marginTop: "1rem" }}>
              No profiles shortlisted
            </p>
          </div>
        </div>
      )}
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from="PROFILE"
          isAgent={true}
          profileDetails={profileDetails}
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
};

export default ShortlistedAgentProfileList;
