import React, { useEffect, useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import Footer from "../Footer";
import { Container } from "react-bootstrap";
import Nva from "../Nva";
import { Helmet } from "react-helmet";
import Faq from "./Faq";
import { useLocation } from "react-router-dom";
import AgentNavbar from "../Agent/AgentNavbar";
import AgentFooter from "../Agent/AgentFooter";

const FrequentQuestions = () => {
  const location = useLocation();
  const token = localStorage.getItem("authToken");
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Dream Life Matrimony</title>
      </Helmet>

      <section className="matches-background">
        {token ? (
          location.pathname === "/agent/frequent-questions" ? (
            <AgentNavbar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
          ) : (
            <Navbars
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
          )
        ) : (
          <Nva />
        )}
        <Container>
          <Faq
            selectedTab={selectedTab}
            titleDiscription={titleDiscription}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
        </Container>
        {location.pathname === "/agent/frequent-questions" ? (
          <AgentFooter />
        ) : (
          <Footer />
        )}
      </section>
    </>
  );
};

export default FrequentQuestions;
