// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editcontact{
    display: flex;
    align-items: center;
    justify-content: center;
}




.div1{
    display: flex;
    align-items: center;
    gap: 20px;
    
}


.mb-3.editcontact.row {
    padding: 0% 0%;
    margin: 20px 0px;
}


p.changeh1{
    padding: 0 !important;
}

.div1 button{
background: #16183E !important;
color: #fff;
border-radius: 10px;
border: none;
height: 40px;
padding: 6px 20px;
}



.div1 button:hover,.div1 button:focus{
    color: #fff;
}

.space1{
    padding: 30px;
}


@media screen and (max-width:767px) {
    .space1{
        padding: 30px 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/contactinformation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;;;;AAKA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;;AAEb;;;AAGA;IACI,cAAc;IACd,gBAAgB;AACpB;;;AAGA;IACI,qBAAqB;AACzB;;AAEA;AACA,8BAA8B;AAC9B,WAAW;AACX,mBAAmB;AACnB,YAAY;AACZ,YAAY;AACZ,iBAAiB;AACjB;;;;AAIA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".editcontact{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n\n\n\n.div1{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    \n}\n\n\n.mb-3.editcontact.row {\n    padding: 0% 0%;\n    margin: 20px 0px;\n}\n\n\np.changeh1{\n    padding: 0 !important;\n}\n\n.div1 button{\nbackground: #16183E !important;\ncolor: #fff;\nborder-radius: 10px;\nborder: none;\nheight: 40px;\npadding: 6px 20px;\n}\n\n\n\n.div1 button:hover,.div1 button:focus{\n    color: #fff;\n}\n\n.space1{\n    padding: 30px;\n}\n\n\n@media screen and (max-width:767px) {\n    .space1{\n        padding: 30px 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
