import React from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Button, Container } from "react-bootstrap";
import { MDBCard, MDBContainer } from "mdb-react-ui-kit";
import Slider from "react-slick";
import { RxCross2 } from "react-icons/rx";
import { FaIndianRupeeSign } from "react-icons/fa6";
import AgentFooter from "../AgentFooter";

const AgentUpgrade = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>Agent-Upgrade | Dream Life Matrimony</title>
      </Helmet>
      <section>
        <AgentNavbar
          selectedTab={""}
          setSelectedTab={() => {}}
          setTitleDiscription={() => {}}
        />
        <MDBContainer className=" mt-5 ">
          <div className="registerform">
            <div className="rgtr">
              <div className="bannr">
                <img
                  src="/Images/rtr.jpg"
                  className="img-fluid "
                  alt="..."
                  style={{ width: 100 }}
                />
              </div>
            </div>
            <MDBContainer>
              <Slider {...settings} className="uprade-items">
                <MDBCard
                  className="cardonly-highlight"
                  style={{ width: "30%" }}
                >
                  <p>
                    <img
                      src="/Images/upgrade3.png"
                      className="bronza-packages-home"
                    ></img>
                  </p>
                  <p className="bronze-package-home">Free Registration</p>
                  <div className="another-packages">
                    <p>
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginRight: "6px",
                        }}
                      >
                        <RxCross2 />
                      </span>
                      0 Contact view Per day
                    </p>
                    <p>
                      {" "}
                      <img src="/Images/ok.png"></img>5 Express Interest Per day
                    </p>
                    <p>
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginRight: "6px",
                        }}
                      >
                        <RxCross2 />
                      </span>
                      Chat with Prospects Directly
                    </p>
                    <p>
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginRight: "6px",
                        }}
                      >
                        <RxCross2 />
                      </span>
                      Send Personalised Messages
                    </p>
                    <p className="full-cash">
                      <FaIndianRupeeSign />
                      <span className="cash">0</span>
                    </p>
                    <h6 className="package-days">Free validity</h6>
                    <Button
                      href="#"
                      className="upgrade-submit-home"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </MDBCard>

                <MDBCard
                  className="cardonly-highlight"
                  style={{ width: "30%" }}
                >
                  <p>
                    <img
                      src="/Images/upgrade1.png"
                      className="bronza-packages-home"
                    ></img>
                  </p>
                  <p className="bronze-package-home">Bronze Package</p>
                  <div className="another-packages">
                    <p>
                      <img src="/Images/ok.png"></img>150 Contact view Per day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>150 Express Interest Per
                      day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Chat with Prospects
                      Directly
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Send Personalised Messages
                    </p>
                    <p className="full-cash">
                      <FaIndianRupeeSign />
                      <span className="cash">1000</span>
                    </p>
                    <h6 className="package-days">1 month</h6>
                    <Button
                      href="#"
                      className="upgrade-submit-home"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </MDBCard>

                <MDBCard
                  className="cardonly-highlight"
                  style={{ width: "30%" }}
                >
                  <p>
                    <img
                      src="/Images/upgrade1.png"
                      className="bronza-packages-home"
                    ></img>
                  </p>
                  <p className="bronze-package-home">Silver Package</p>
                  <div className="another-packages">
                    <p>
                      <img src="/Images/ok.png"></img>300 Contact view Per day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>300 Express Interest Per
                      day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Chat with Prospects
                      Directly
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Text chatting facility-yes
                    </p>
                    <p className="full-cash">
                      <FaIndianRupeeSign />
                      <span className="cash">2500</span>
                    </p>
                    <h6 className="package-days">3 Months</h6>
                    <Button
                      href="#"
                      className="upgrade-submit-home"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </MDBCard>
                <MDBCard
                  className="cardonly-highlight"
                  style={{ width: "30%" }}
                >
                  <p>
                    <img
                      src="/Images/upgrade1.png"
                      className="bronza-packages-home"
                    ></img>
                  </p>
                  <p className="bronze-package-home">Gold Package</p>
                  <div className="another-packages">
                    <p>
                      <img src="/Images/ok.png"></img>600 Contact view Per day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>600 Express Interest Per
                      day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Chat with Prospects
                      Directly
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Text chatting facility-Yes
                    </p>
                    <p className="full-cash">
                      <FaIndianRupeeSign />
                      <span className="cash">4000</span>
                    </p>
                    <h6 className="package-days">6 months</h6>
                    <Button
                      href="#"
                      className="upgrade-submit-home"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </MDBCard>
                <MDBCard
                  className="cardonly-highlight"
                  style={{ width: "30%" }}
                >
                  <p>
                    <img
                      src="/Images/upgrade1.png"
                      className="bronza-packages-home"
                    ></img>
                  </p>
                  <p className="bronze-package-home">Diamond Package</p>
                  <div className="another-packages">
                    <p>
                      <img src="/Images/ok.png"></img>1000 Contact view Per day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>1000 Express Interest Per
                      day
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Chat with Prospects
                      Directly
                    </p>
                    <p>
                      <img src="/Images/ok.png"></img>Text chatting facility-yes
                    </p>
                    <p className="full-cash">
                      <FaIndianRupeeSign />
                      <span className="cash">7500</span>
                    </p>
                    <h6 className="package-days">12 Months</h6>
                    <Button
                      href="#"
                      className="upgrade-submit-home"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </MDBCard>
              </Slider>
            </MDBContainer>
          </div>
        </MDBContainer>
        <AgentFooter />
      </section>
    </>
  );
};

export default AgentUpgrade;
