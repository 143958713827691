import React, { useEffect, useState } from "react";
import api from "../../../../Api/InterationApi";
import UserCard from "./UserCard";
import ReactPaginate from "react-paginate";

const Block = () => {
  const token = localStorage.getItem("authToken");
  const [blockedProfile, setBlockedProfile] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchBlockedProfile();
  }, [pageDetails]);

  const fetchBlockedProfile = async () => {
    setBlockedProfile([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    await api.getBlockedProfiles(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setBlockedProfile(data?.data?.block_user);
      }
    });
  };

  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };
  return (
    <>
      <div className="pictures-client">
        {blockedProfile?.length > 0 ? (
          blockedProfile.map((interation, index) => (
            <React.Fragment key={index}>
              <UserCard
                user={{
                  name: interation?.name,
                  userId: interation?.profile_code,
                  image: interation?.profile_image,
                  createdBy: interation?.profile_created_for,
                  age: interation?.age,
                  height_cm: interation?.height_cm,
                  height_feet: interation?.height_feet,
                  religion: interation?.religion,
                  education: interation?.education,
                  district: interation?.district,
                  date: interation?.date,
                  occupation: interation?.occupation,
                  gender: interation?.gender,
                }}
                blockProfile={blockedProfile}
                setBlockProfile={setBlockedProfile}
              />
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
            <p style={{ color: "#16183E", marginTop: "1rem" }}>
              {" "}
              No data found
            </p>
          </div>
        )}
      </div>
      <div className="desktop-pagination">
        {pagination?.totalPages > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={
                <i className="fa pagination-next fa-angle-left"></i>
              }
              nextLabel={<i className="fa pagination-next fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination?.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"} // Define a CSS class for each page number element
              pageLinkClassName={"page-link"} // Define a CSS class for the page number link
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Block;
