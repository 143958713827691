import React from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalFooter, MDBBtn } from 'mdb-react-ui-kit';

const CustomModal = ({ show, handleClose, title, message }) => {
  return (
    <MDBModal show={show} onHide={handleClose}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader className='submit-register text-center'>
            <MDBModalTitle className='register-sucess-title mx-auto text-center'>{title}</MDBModalTitle>
            <MDBBtn color='none' onClick={handleClose} className='custom-close-button'style={{backgroundColor:'#16183E'}}>&times;</MDBBtn>
          </MDBModalHeader>
          <MDBModalFooter  className='text-center changepassword-footercontent'  >
            <p className='text-center'>{message}</p> 
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default CustomModal;
