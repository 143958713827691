import axios from "axios";

const API_CONFIG = {
  baseURL: "https://dlmbiz.com/api",
  headers: {
    "Content-Type": "application/json",
    "X-API-KEY":
      "nuaOgTF5ZGS9krJMAlDklUqmfFoSd1efkA7Z38QQmyQJbRVJBAvPyeAbAmUw1M9x",
  },
};

const api = axios.create(API_CONFIG);

class DMLUpgradeFetch {
  fetchPlans() {
    return api
      .get("/voucher/plans")
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  verifyVoucher(payload) {
    return api
      .post("/voucher/verify", payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occurred.");
      });
  }
  voucherUse(payload) {
    return api
      .post("/voucher/use", payload)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new Error("An unexpected error occured.");
      });
  }
}

export default new DMLUpgradeFetch();
