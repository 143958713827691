import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import AllProfiles from "../AllProfiles";
import AgentAddNewProfileMain from "../AddProfile/Main";
import AgentMessagesList from "./MessagesList";
import AgentFooter from "../AgentFooter";

const AgentMessages = ({ tab }) => {
  const [selectedTab, setSelectedTab] = useState(tab ? tab : "Home");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [selectedTab]);
  return (
    <>
      <Helmet>
        <title>Agent-Notificatons | Dream Life Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <AgentNavbar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={() => {}}
        />
        <Container>
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={() => {}}
            />
            {selectedTab === "Home" && <AgentMessagesList />}
            {selectedTab === "AllProfiles" && <AllProfiles />}
            {selectedTab === "AddProfile" && <AgentAddNewProfileMain />}
          </MDBRow>
        </Container>
      </section>
      <AgentFooter />
    </>
  );
};

export default AgentMessages;
