import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import '../style/footer.css'


function Footerinner() {
  return (
    <div className='footer' >
      <MDBFooter className='text-center text-lg-start '>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3 footer_inner'>
              <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                <a href='/'><img src='../Images/logo-white.png' className='img-fluid white-logo-footer ' alt='...' style={{ width: '100%' }} /></a>
                <p className='footer_content'>
                  Dream Life Matrimony is a community portal offering online matrimonial services.
                  Register with us for FREE to find a partner from your own community.
                  Take advantage of our user friendly search features to find a bride or groom who matches your preferences.
                  Join us and begin your happy journey today!
                </p>
              </MDBCol>

              <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 useful-all-links'>Useful Links</h6>
                <p className='text-reset usefull_items'>
                  <a href='my-home' className='text-reset'>
                    Home
                  </a>
                </p>

                <p className='text-reset usefull_items'>
                  <a href='success-stories' className='text-reset'>
                    Success Stories
                  </a>
                </p>
                <p className='text-reset usefull_items'>
                  <a href='matches' className='text-reset'>
                    Matches
                  </a>
                </p>

              </MDBCol>

              <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 useful-all-links'>Religion</h6>
                <p className='text-reset usefull_items'>
                  <a href='' className='text-reset usefull_items'>
                    Privacy policy
                  </a>
                </p>

                <p className='text-reset usefull_items usefull_items'>
                  <a href='messages' className='text-reset'>
                    Messages
                  </a>
                </p>
                <p className='text-reset usefull_items'>
                  <a href='search' className='text-reset'>
                    Search
                  </a>
                </p>
                <p className='text-reset usefull_items'>
                  <a href='upgrade' className='text-reset'>
                    Upgrade Now
                  </a>
                </p>
              </MDBCol>

              <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 useful-all-links'>Contact</h6>
                <p className='text-reset' style={{ display: 'flex', alignItems: 'center' }}>

                  <MDBIcon fas icon="map-marker-alt" />
                  Dream Life Matrimony, <br/> Door No.CC 35/1398C3, 2nd Floor,City Tower,<br/> MKK Nair Road, Palarivattom, Kochi- 682025
               
                </p>

                <p className='text-reset footer-size '>
                  <MDBIcon fas icon="phone-alt" /> <a href="tel:+919539990515">+91 9539990515</a>
                </p>
                <p className='text-reset  footer-size'>
                  <MDBIcon fas icon="globe-asia" /> <a href="mailto:noreply@xyz.com">noreply@xyz.com</a>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4' style={{ backgroundColor: '#fff', color: '#1C1B1B' }}>
          <div className="d-flex justify-content-center copy-color" >

            <a className='text-reset footer-copy   ' href='' style={{ color: '#1C1B1B' }} >
            Dream Life Matrimony | Copyright © 2024. All rights reserved.
            </a>
          </div>
        </div>
      </MDBFooter>
    </div>
  )
}

export default Footerinner