import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const AgentFooter = () => {
  return (
    <div className="footer">
      <MDBFooter className="text-center text-lg-start ">
        <section className="">
          <MDBContainer className="text-center text-md-start">
            <MDBRow className="footer_gap">
              <MDBCol md="6" lg="4" xl="3" className="mx-auto mb-4">
                <Link to="/agent">
                  <img
                    src="/Images/logo-white.png"
                    className="img-fluid white-logo-footer "
                    alt="..."
                    style={{ width: "100%" }}
                  />
                </Link>
                <p className="footer_content">
                  <span className="text-start" style={{ marginRight: "3px" }}>
                    Indian Brides & Grooms
                  </span>
                  <span className="text-start">Matrimony</span> is a community
                  portal offering online matrimonial services. Register with us
                  for FREE to find a partner from your own community. Take
                  advantage of our user friendly search features to find a bride
                  or groom who matches your preferences. Join us and begin your
                  happy journey today!
                </p>
              </MDBCol>

              <MDBCol md="6" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 useful-all-links">
                  Useful Links
                </h6>
                <p className="text-footer usefull_items">
                  <Link to={"/agent"} className="text-footer">
                    Home
                  </Link>
                </p>
                <p className="text-footer usefull_items">
                  <Link to="/agent/success-stories" className="text-footer">
                    Success Stories
                  </Link>
                </p>
                  <p className="text-footer usefull_items">
                    <Link to="/agent/messages" className="text-footer">
                      Messages
                    </Link>
                  </p>
              </MDBCol>

              <MDBCol md="6" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 useful-all-links">
                  Help
                </h6>

                <p className="text-footer usefull_items">
                  <Link
                    to="/agent/privacy-policy"
                    className="text-footer usefull_items"
                  >
                    Privacy policy
                  </Link>
                </p>

                <p className="text-footer usefull_items">
                  <Link
                    to="/agent/frequent-questions"
                    className="text-footer usefull_items"
                  >
                    FAQs
                  </Link>
                </p>

                <p className="text-footer usefull_items usefull_items">
                  <Link to="/agent/contact" className="text-footer">
                    Contact Us
                  </Link>
                </p>
              </MDBCol>

              <MDBCol md="6" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <div className="footer-addressmobile">
                  <h6 className="text-uppercase fw-bold mb-4 useful-all-links">
                    Address
                  </h6>

                  <div className="text-footer d-flex align-items-center mb-3 gap-3">
                    <div className="footer-addressicons">
                      <MDBIcon fas icon="map-marker-alt" />
                    </div>
                    <div className="address-footermobile">
                      Dream Life Matrimony, <br /> Door No.CC
                      35/1398C3, 2nd Floor,City Tower,
                      <br /> MKK Nair Road, Palarivattom, Kochi- 682025
                    </div>
                  </div>

                  <div className="text-footer footer-size d-flex align-items-center mb-3 gap-2">
                    <div className="footer-addressicons">
                      <MDBIcon fas icon="phone-alt" />
                    </div>
                    <a href="tel:+919539990515">+91 9539990515</a>
                  </div>
                  <div className="text-footer footer-size d-flex align-items-center gap-2">
                    <div className="footer-addressicons">
                      <MDBIcon fas icon="globe-asia" />
                    </div>
                    <a href="mailto:noreply@xyz.com">
                      noreply@xyz.com
                    </a>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "#fff", color: "#1C1B1B" }}
        >
          <div className="d-flex justify-content-center copy-color">
            <a className="footer-copy" href="" style={{ color: "#1C1B1B" }}>
              Dream Life Matrimony | Copyright © 2024. All rights
              reserved.
            </a>
          </div>
        </div>
      </MDBFooter>
    </div>
  );
};

export default AgentFooter;
