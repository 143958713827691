import React, { useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import MatchSection from "./MatchSection";
import Footer from "../Footer";
import InterationSection from "../Interations/InterationSection";
import { Helmet } from "react-helmet";

const Matches = () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );

  return (

    <>

    <Helmet>
        <title>Matches | Dream Life Matrimony</title>
      </Helmet>
    <section className="matches-background">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      <Container>
        <MDBRow className="myhome-matches-all">
          <ProfileSideBar
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
          {selectedTab === "Home" ? (
            <MatchSection />
          ) : (
            <InterationSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              titleDiscription={titleDiscription}
              setTitleDiscription={setTitleDiscription}
            />
          )}
        </MDBRow>
      </Container>
      <Footer />
    </section>
    </>
  );
};

export default Matches;
