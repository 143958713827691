import {
    MDBBtn,
    MDBInput,
    MDBModal,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
  } from "mdb-react-ui-kit";
  import React, { useState } from "react";
  import { Form } from "react-bootstrap";
  import { BsEye, BsEyeSlash } from "react-icons/bs";
  import api from "../../../Api/AuthApi";
  
  const AgentResetPassword = ({ modalType, setModalType, forgotPassword }) => {
    const [newPassword, setNewPassword] = useState({
      value: "",
      error: "",
    });
    const [confirmPassword, setConfirmPassword] = useState({
      value: "",
      error: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
    const isValidPassword = (password) => {
      // Password must be at least 8 characters long and contain at least one letter and one digit
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
      return passwordRegex.test(password);
    };
  
    const handlePassword = (e) => {
      const { value, name } = e.target;
  
      if (name === "newpassword") {
        if (value) {
          if (!isValidPassword(value)) {
            setNewPassword((prevValue) => ({
              ...prevValue,
              error:
                "Password must be at least 8 characters long and contain at least one letter and one digit",
            }));
          } else if (
            confirmPassword?.value &&
            confirmPassword?.value !== newPassword?.value
          ) {
            setNewPassword((prevValue) => ({
              ...prevValue,
              error: "New password and confirm password should be match",
            }));
          } else {
            setNewPassword((prevValue) => ({
              ...prevValue,
              error: "",
            }));
          }
        } else {
          setNewPassword((prevValue) => ({
            ...prevValue,
            error: "Please enter new password",
          }));
        }
        setNewPassword((prevValue) => ({
          ...prevValue,
          value: value,
        }));
      } else if (name === "confirmpassword") {
        if (value) {
          if (!isValidPassword(value)) {
            setConfirmPassword((prevValue) => ({
              ...prevValue,
              error:
                "Password must be at least 8 characters long and contain at least one letter and one digit",
            }));
          } else {
            setConfirmPassword((prevValue) => ({
              ...prevValue,
              error: "",
            }));
          }
        } else if (
          newPassword?.value &&
          newPassword?.value !== confirmPassword?.value
        ) {
          setConfirmPassword((prevValue) => ({
            ...prevValue,
            error: "New password and confirm password should be match",
          }));
        } else {
          setConfirmPassword((prevValue) => ({
            ...prevValue,
            error: "Please enter confirm password",
          }));
        }
        setConfirmPassword((prevValue) => ({
          ...prevValue,
          value: value,
        }));
      }
    };
  
    const onSubmit = () => {
      if (!newPassword?.value) {
        setNewPassword((prevValue) => ({
          ...prevValue,
          error: "Please enter new password",
        }));
      }
      if (!confirmPassword?.value) {
        setConfirmPassword((prevValue) => ({
          ...prevValue,
          error: "Please enter confirm password",
        }));
      }
  
      if (newPassword?.value && confirmPassword?.value) {
        const payload = {
          newPassword: newPassword?.value,
          confirmPassword: confirmPassword?.value,
          user: forgotPassword?.user,
        };
        api.resetPassword(payload, forgotPassword?.authToken).then(({ data }) => {
          if (data.status) {
            setModalType("success");
          } else {
            setConfirmPassword((prevValue) => ({
              ...prevValue,
              error: data?.message,
            }));
          }
        });
      }
    };
    return (
      <MDBModal
        show={modalType === "password" ? true : false}
        tabIndex="-1"
        className="model-full"
        onHide={() => {
          setModalType("");
        }}
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader className="forgotpassword-header">
              <div className="forgot-header">
                <MDBModalTitle>Reset Your Password</MDBModalTitle>
              </div>
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                onClick={() => setModalType("")}
              ></MDBBtn>
            </MDBModalHeader>
            <p className="forgot-paragraph">Enter your new password</p>
            <div className="box-size-forgot">
              <div className="mb-4">
                <Form.Group
                  controlId="formBasicPassword"
                  style={{ position: "relative" }}
                >
                  <MDBInput
                    wrapperClass="mb-0"
                    className="varificationid"
                    label="New Password"
                    name="newpassword"
                    id="formControlLg-login"
                    type={showPassword ? "text" : "password"}
                    size="lg"
                    value={newPassword?.value}
                    onChange={handlePassword}
                  />
                  {newPassword?.error && (
                    <p className="m-0 text-danger">{newPassword?.error}</p>
                  )}
  
                  <span
                    className="password-toggle-icon-login"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      position: "absolute",
                      top: "44%",
                      color: "#16183E",
                      right: 14,
                      transform: "translateY(-70%)",
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                </Form.Group>
              </div>
            </div>
            <div className="box-size-forgot position-relative">
              <div className="mb-4">
                <Form.Group
                  controlId="formBasicPassword"
                  style={{ position: "relative" }}
                >
                  <MDBInput
                    wrapperClass="mb-0"
                    className="varificationid"
                    label="Confirm Password"
                    name="confirmpassword"
                    id="formControlLg-login"
                    type={showConfirmPassword ? "text" : "password"}
                    size="lg"
                    value={confirmPassword?.value}
                    onChange={handlePassword}
                  />
                  {confirmPassword?.error && (
                    <p className="m-0 text-danger">{confirmPassword?.error}</p>
                  )}
                  <span
                    className="password-toggle-icon-login"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      position: "absolute",
                      top: "54%",
                      right: "15px",
                      transform: "translateY(-70%)",
                      cursor: "pointer",
                      color: "#16183E",
                    }}
                  >
                    {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                </Form.Group>
              </div>
            </div>
            <MDBModalFooter>
              <MDBBtn className="forgot-submit" onClick={onSubmit}>
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  };
  
  export default AgentResetPassword;
  