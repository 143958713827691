import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import "../style/navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxChatBubble, RxUpdate } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { GoGear } from "react-icons/go";
import { CgLogOut } from "react-icons/cg";

import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../firebase/firebase";
import api from "../Api/ProfileApi";
import authApi from "../Api/AuthApi";
import {
  getFcmToken,
  getInteractionCount,
  getNotificationCount,
  getProfileDetails,
  setInteractionCounts,
  setNotificationCount,
  setProfileDetails,
} from "../redux/features/profile";
// import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import { BsHouseDoor } from "react-icons/bs";
import { LuHeartHandshake } from "react-icons/lu";
function Navbars({ selectedTab, setSelectedTab, setTitleDiscription }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const getUserDetails = useSelector(getProfileDetails);
  const getNoteCount = useSelector(getNotificationCount);
  const getCounts = useSelector(getInteractionCount);
  const FcmToken = useSelector(getFcmToken);
  const firebaseDB = getFirestore(firebaseApp);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [messageCount, setMessageCount] = useState(0);
  const [userPhoto, setuserPhoto] = useState(null);
  const [matchCount, setMatcheCount] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      if (getUserDetails?.profile_id) {
        updateOnline(getUserDetails?.profile_id);
      }
    }
  }, []);

  const updateOnline = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    }
  };

  // useEffect(() => {
  //   // Add event listeners to detect changes in network status
  //   const handleOnline = () => setIsOnline(true);
  //   const handleOffline = () => setIsOnline(false);

  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   // Cleanup: remove event listeners when the component unmounts
  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = async (event) => {
  //     if (getUserDetails?.profile_id) {
  //       const currentTimeInUTC = new Date().toUTCString();
  //       await updateDoc(doc(firebaseDB, "users", getUserDetails?.profile_id), {
  //         status: "OFFLINE",
  //         time: currentTimeInUTC,
  //       });
  //     }
  //     return;
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   const updateOnlineStatus = async () => {
  //     if (getUserDetails?.profile_id) {
  //       let status = "";
  //       if (isOnline) {
  //         status = "ONLINE";
  //       } else {
  //         status = "OFFLINE";
  //       }

  //       const usersDocRef = doc(
  //         firebaseDB,
  //         "users",
  //         getUserDetails?.profile_id
  //       );
  //       const userDocSnap = await getDoc(usersDocRef);
  //       const currentTimeInUTC = new Date().toUTCString();
  //       if (!userDocSnap.exists()) {
  //         const newUuid = await uuid();
  //         await setDoc(doc(firebaseDB, "users", getUserDetails?.profile_id), {
  //           id: newUuid,
  //           userId: getUserDetails?.profile_id,
  //           status: status,
  //           time: currentTimeInUTC,
  //         });
  //       } else {
  //         await updateDoc(
  //           doc(firebaseDB, "users", getUserDetails?.profile_id),
  //           {
  //             status: status,
  //             time: currentTimeInUTC,
  //           }
  //         );
  //       }
  //     }
  //   };

  //   return () => {
  //     updateOnlineStatus();
  //   };
  // }, [isOnline]);

  // useEffect(() => {
  //   if (isSupported()) {
  //     const firebaseMessage = getMessaging(firebaseApp);
  //     onMessage(firebaseMessage, (payload) => {
  //       if (payload) {
  //         toast(` ✔️ ${payload.notification.body}`, {
  //           position: "bottom-right",
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });

  //         dispatch(
  //           setNotificationCount(
  //             getNoteCount === null || getNoteCount === 0 ? 1 : getNoteCount + 1
  //           )
  //         );
  //       }
  //     });
  //   }
  // }, []);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  //photo view
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          document.cookie =
            "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          // Clear the local storage item (if you use it for storing the token)
          localStorage.clear();
          navigate("/login");
          return;
        }

        await api.getUserProfile(token).then(({ data }) => {
          if (data.status) {
            setuserPhoto(data?.user_profile?.profile_image);
            dispatch(setProfileDetails(data?.user_profile));
            dispatch(setNotificationCount(data?.notification_count));
            const interactionCounts = {
              interestedProfiles: data?.interested_profile_count,
              interestedRecieved: data?.interest_received_count,
              acceptedProfiles: data?.interest_accepted_count,
              acceptedByOtherProfiles: data?.interest_accepted_other_count,
              rejectedProfiles: data?.interest_rejected_profile_count,
              rejectedByOtherProfiles: data?.interest_rejected_other_count,
              shortlistedMe: data?.shortlist_by_me_count,
              shortlistedByOther: data?.shortlist_by_other_count,
              visitedProfiles: data?.visited_count,
            };
            dispatch(setInteractionCounts(interactionCounts));
          } else {
            if (data.statusCode === 401) {
              localStorage.clear();
              navigate("/login");
            }
          }
        });
      } catch (error) {
        console.error("Error fetching user newmatches details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    // const unSub = onSnapshot(
    //   query(
    //     collection(firebaseDB, "lastMessages"),
    //     where("receiverId", "==", getUserDetails?.profile_id),
    //     where("isViewed", "==", false)
    //   ),
    //   (querySnapShot) => {
    //     const data = querySnapShot.docs.map((doc) => doc.data());
    //     const notRejectedData = data.filter((da) => da.status !== "REJECTED");
    //     setMessageCount(notRejectedData.length);
    //   }
    // );

    return () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        onSnapshot(
          query(
            collection(firebaseDB, "lastMessages"),
            where("receiverId", "==", getUserDetails?.profile_id),
            where("isViewed", "==", false)
          ),
          (querySnapShot) => {
            const data = querySnapShot.docs.map((doc) => doc.data());
            const notRejectedData = data.filter(
              (da) => da.status !== "REJECTED"
            );
            setMessageCount(notRejectedData.length);
          }
        );
      }
    };
  }, []);

  const navigate = useNavigate();
  //logout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      console.log("Authentication token", token);

      await authApi.logout({ fcmtoken: FcmToken }, token);
      await updateOnlineStatus(getUserDetails?.profile_id);
      // Clear the local browser cookie
      document.cookie =
        "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      // Clear the local storage item (if you use it for storing the token)
      localStorage.clear();
      navigate("/login");

      console.log("logout sucessful");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const updateOnlineStatus = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "OFFLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "OFFLINE",
        time: currentTimeInUTC,
      });
    }
  };

  const navigateNotifications = () => {
    navigate("/notifications");
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Navbar
        collapseOnSelect
        className="navbarnav"
        expand="lg"
        sticky="top"
        bg=""
        variant="dark"
      >
        <Container className="logoContainer">
          <p
            className="mobi"
            style={{ marginBottom: "0px" }}
            onClick={handleShow}
          >
            <span className="navbar-toggler-icon"></span>
          </p>

          <Offcanvas className="navbar-close" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <hr></hr>
              <div className="empeditss">
                <li className="empedit">
                  <a href="/add-photo">ADD/EDIT PHOTOS</a>
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li className="empedit">
                  <a href="/edit-profile">VIEW/EDIT PROFILE</a>
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Interest Sent" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Interest Sent");
                    setTitleDiscription("Members you have interest ");
                    handleClose();
                  }}
                >
                  INTERESTED PROFILE {""}
                  {getCounts?.interestedProfiles > 0 && (
                    <span>({getCounts?.interestedProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Interested Received" &&
                    "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Interested Received");
                    setTitleDiscription(
                      " Members who sent has interest in you!"
                    );
                    handleClose();
                  }}
                >
                  INTERESTED RECEIVED {""}
                  {getCounts?.interestedRecieved > 0 && (
                    <span>({getCounts?.interestedRecieved})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Accepted Profile" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Accepted Profile");
                    setTitleDiscription("Members you have accepted");
                    handleClose();
                  }}
                >
                  ACCEPTED PROFILE {""}
                  {getCounts?.acceptedProfiles > 0 && (
                    <span>({getCounts?.acceptedProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Accepted By Other" &&
                    "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Accepted By Other");
                    setTitleDiscription("Members you have accepted");
                    handleClose();
                  }}
                >
                  ACCEPTED BY OTHER {""}
                  {getCounts?.acceptedByOtherProfiles > 0 && (
                    <span>({getCounts?.acceptedByOtherProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Rejected Profile" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Rejected Profile");
                    setTitleDiscription("Members who rejected you!");
                    handleClose();
                  }}
                >
                  REJECTED PROFILE {""}
                  {getCounts?.rejectedProfiles > 0 && (
                    <span>({getCounts?.rejectedProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Rejected By Other" &&
                    "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Rejected By Other");
                    setTitleDiscription("Members who rejected you!");
                    handleClose();
                  }}
                >
                  REJECTED BY OTHER {""}
                  {getCounts?.rejectedByOtherProfiles > 0 && (
                    <span>({getCounts?.rejectedByOtherProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Shortlisted By Other" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Shortlisted By Other");
                    setTitleDiscription("Members Who have Shortlisted you!");
                    handleClose();
                  }}
                >
                  SHORTLISTED BY OTHER {""}
                  {getCounts?.shortlistedMe > 0 && (
                    <span>({getCounts?.shortlistedMe})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "My Shortlist" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("My Shortlist");
                    setTitleDiscription("Members Who have Shortlisted by you!");
                    handleClose();
                  }}
                >
                  MY SHORTLIST {""}
                  {getCounts?.shortlistedByOther > 0 && (
                    <span>({getCounts?.shortlistedByOther})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Who Visited My Profile" &&
                    "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Who Visited My Profile");
                    setTitleDiscription("Members Visited!");
                    handleClose();
                  }}
                >
                  WHO VISITED {""}
                  {getCounts?.visitedProfiles > 0 && (
                    <span>({getCounts?.visitedProfiles})</span>
                  )}
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Hide Profile" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Hide Profile");
                    setTitleDiscription("Members Hide!");
                    handleClose();
                  }}
                >
                  HIDE PROFILE
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Contact Viewed" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Contact Viewed");
                    setTitleDiscription("Members whose contact you have viewed!");
                    handleClose();
                  }}
                >
                  CONTACT VIEWED
                </li>
                <hr></hr>
              </div>
              <div className="empeditss">
                <li
                  className={`empedit cursor-pointer ${
                    selectedTab === "Blocked Profile" && "selected-myhome-list"
                  }`}
                  onClick={() => {
                    setSelectedTab("Blocked Profile");
                    setTitleDiscription("Members Who Blocked!");
                    handleClose();
                  }}
                >
                  BLOCKED PROFILE
                </li>
                <hr></hr>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <a href="/my-home">
            <img src="/Images/logo-white.png" alt="My Image" className="logo" />
          </a>
          <div className="mobi">
            <div
              className="notification cursor-pointer"
              onClick={navigateNotifications}
            >
              <img src="../Images/notification.png" alt="Notifications" />
              {getNoteCount > 0 && (
                <span className="notification-count">{getNoteCount}</span>
              )}
            </div>

            <div
              className="notification-login"
              onClick={() => setPopupVisible(!popupVisible)}
            >
              {getUserDetails?.profile_image ? (
                <img
                  src={getUserDetails?.profile_image}
                  alt="Profile"
                  onClick={togglePopup}
                  className="rounded-profile-image"
                />
              ) : (
                <img
                  src="../Images/profile.png"
                  alt="Profile"
                  onClick={togglePopup}
                  className="rounded-profile-image"
                />
              )}
              {popupVisible && (
                <div className="popup-profile" onClick={togglePopup}>
                  <div className="mb-1">
                    <a href="/my-home">
                      <div className="d-flex align-items-center gap-1">
                        <BsHouseDoor
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">My Home</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/edit-profile">
                      <div className="d-flex align-items-center gap-1">
                        <MDBIcon
                          far
                          icon="user"
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">Edit Profile</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/matches">
                      <div className="d-flex align-items-center gap-1">
                        <LuHeartHandshake
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">Matches</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/messages">
                      <div className="d-flex align-items-center gap-1">
                        <RxChatBubble
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">
                          Messages
                          {messageCount > 0 && (
                            <span className="count">({messageCount})</span>
                          )}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/search" className="flex align-items-center">
                      <div className="d-flex align-items-center gap-1">
                        <IoSearchOutline
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">Search</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/upgrade">
                      <div className="d-flex align-items-center gap-1">
                        <RxUpdate
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">Upgrade now</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="/settings">
                      <div className="d-flex align-items-center gap-1">
                        <GoGear
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />
                        <span className="setting-edit">Settings</span>
                      </div>
                    </a>
                  </div>
                  <div className="mb-1">
                    <a href="#" onClick={handleLogout}>
                      <div className="d-flex align-items-center gap-1">
                        <CgLogOut
                          style={{ fontSize: "17px", color: "#16183E" }}
                        />{" "}
                        <span className="setting-edit">Logout</span>
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav" className="nav-login">
            <div className="navbar_itesms">
              <Nav className="menubar">
                <Nav.Link
                  as={Link}
                  to="/my-home"
                  exact="true"
                  style={{
                    borderBottom:
                      pathname === "/my-home" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MY HOME
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/matches"
                  style={{
                    borderBottom:
                      pathname === "/matches" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MATCHES
                  {matchCount > 0 && (
                    <span className="count">({matchCount})</span>
                  )}
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/messages"
                  style={{
                    borderBottom:
                      pathname === "/messages" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  MESSAGES
                  {messageCount > 0 && (
                    <span className="count">({messageCount})</span>
                  )}
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/search"
                  style={{
                    borderBottom:
                      pathname === "/search" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  SEARCH
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/upgrade"
                  style={{
                    borderBottom:
                      pathname === "/Upgrade" ? "1px solid #F7AEE2" : "none",
                    fontSize: "13px",
                  }}
                >
                  UPGRADE NOW
                </Nav.Link>

                <div className="desk">
                  <div
                    className="notification cursor-pointer"
                    onClick={navigateNotifications}
                  >
                    <img src="../Images/notification.png" alt="Notifications" />
                    {getNoteCount > 0 && (
                      <span className="notification-count">{getNoteCount}</span>
                    )}
                  </div>

                  <div
                    className="notification-login"
                    onClick={() => setPopupVisible(!popupVisible)}
                  >
                    {getUserDetails?.profile_image ? (
                      <img
                        src={getUserDetails?.profile_image}
                        alt="Profile"
                        onClick={togglePopup}
                        className="rounded-profile-image"
                      />
                    ) : (
                      <img
                        src="../Images/profile.png"
                        alt="Profile"
                        onClick={togglePopup}
                        className="rounded-profile-image"
                      />
                    )}

                    {popupVisible && (
                      <div className="popup-profile" onClick={togglePopup}>
                        <a href="/edit-profile">
                          <MDBIcon far icon="user" />{" "}
                          <span className="setting-edit">Edit Profile</span>
                        </a>
                        <br />
                        <br />
                        <a href="/settings">
                          <MDBIcon fas icon="cog" size="1x" /> Settings
                        </a>
                        <br />
                        <br />
                        <a href="#" onClick={handleLogout}>
                          <MDBIcon fas icon="sign-out-alt" size="1x" /> Logout
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbars;
