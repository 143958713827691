import React, { useEffect, useState } from "react";
import Navbars from "../Navbars";
import Nva from "../Nva";
import Story from "./Story";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import Post from "./Post";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import AgentNavbar from "../Agent/AgentNavbar";
import AgentFooter from "../Agent/AgentFooter";

function SuccessStory() {
  const location = useLocation();
  const [selectTab, setSelectTab] = useState("");
  const token = localStorage.getItem("authToken");
  return (
    <>
      <Helmet>
        <title>SuccessStory | Dream Life Matrimony</title>
      </Helmet>
      <div className="registerbody">
        {token ? (
          location.pathname === "/agent/success-stories" ? (
            <AgentNavbar />
          ) : (
            <Navbars />
          )
        ) : (
          <Nva />
        )}
        <div className="registerbackground">
          <MDBContainer className="mt-5 post-story-success-paragraph">
            <div className="registerform">
              <div className="rgtr">
                <div className="bannr">
                  <img
                    src="../Images/rtr.jpg"
                    className="img-fluid "
                    alt="..."
                    style={{ width: 100 }}
                  />
                </div>
              </div>

              {selectTab === "post" ? (
                <Post setSelectTab={setSelectTab} />
              ) : (
                <Story setSelectTab={setSelectTab} />
              )}
            </div>
          </MDBContainer>
        </div>
        {location.pathname === "/agent/success-stories" ? (
          <AgentFooter />
        ) : (
          <Footer />
        )}
      </div>
    </>
  );
}

export default SuccessStory;
