// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-link {
    background-color: white !important;
    /* Add other styles as needed */
  }
  .matches-buttons.active {
    border: 1px solid #16183E !important;
    background-color: white !important;
    color: #16183E !important; /* Set the text color for better visibility */
    /* Add any other styles you want for the active button here */
  }

  .matches-buttons.active a {
    color: #16183E !important;
  }

  .menu-wrapper .menu-item a :active{
    color: #16183E !important;
  }

  .count_matches{
    font-size:  18 px;
    font-weight:  bold;
    color: #e4c262 !important;
  }`, "",{"version":3,"sources":["webpack://./src/style/slider.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,+BAA+B;EACjC;EACA;IACE,oCAAoC;IACpC,kCAAkC;IAClC,yBAAyB,EAAE,6CAA6C;IACxE,6DAA6D;EAC/D;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;EAC3B","sourcesContent":[".active-link {\n    background-color: white !important;\n    /* Add other styles as needed */\n  }\n  .matches-buttons.active {\n    border: 1px solid #16183E !important;\n    background-color: white !important;\n    color: #16183E !important; /* Set the text color for better visibility */\n    /* Add any other styles you want for the active button here */\n  }\n\n  .matches-buttons.active a {\n    color: #16183E !important;\n  }\n\n  .menu-wrapper .menu-item a :active{\n    color: #16183E !important;\n  }\n\n  .count_matches{\n    font-size:  18 px;\n    font-weight:  bold;\n    color: #e4c262 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
