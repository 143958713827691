import Navbars from './Navbars';
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap';
import '../style/editphoto.css'
import React, { useState } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import Footer from './Footer'

import { useNavigate } from 'react-router-dom';
import Footerinner from './Footerinner';


function Editphoto() {

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();



  const handleUpload = (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsUploading(true);
    setShowModal(true);
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;
        } else {
          clearInterval(interval);
          setIsUploading(false);
          setShowModal(false);
          navigate('/uploaded-image', { state: { imageUrl: URL.createObjectURL(selectedFile) } });
          return prevProgress;
        }
      });
    }, 500);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const imagePreviewUrl = '../Images/editimage.png';


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  return (
    <div className='all-background'>
      <Navbars />

      <Container className='photos-full'>
        <MDBRow className='mt-5  ml-3 edit-photo-only'>

          <MDBCol size='6' sm='4'>
            <div className='editimages'>
              <div className='cards'>
                <form onSubmit={(e) => handleUpload(e)}>
                  <label className='custom-file-upload fas'>
                    <div className='img-wrap img-upload'>
                      <img alt='Profile' className='photo-image' htmlFor='photo-upload' src={selectedFile ? URL.createObjectURL(selectedFile) : imagePreviewUrl} />
                    </div>
                    <input id='photo-upload' type='file' className='photo-file' onChange={handleFileChange} />
                  </label>
                  <button type='submit' className='edit button-class'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </MDBCol>
          <MDBCol size='6' sm='8' className='add-your-photo'>
            <p className='editphoto-heading'>Add your photo and get much better responses!</p>
            <p className='upload-size'> Upload Within 15 MB of size | jpg / gif / png
            </p>
            <form onSubmit={handleFileChange}>
              <label htmlFor="myFile" className='uploadbutton'>
                {selectedFile ? selectedFile.name : 'Upload'}
              </label>
              <input type="file" id="myFile" name="filename" onChange={handleFileChange} style={{ display: 'none' }} />
            </form>
          </MDBCol>

        </MDBRow>
      </Container>
      <Footer></Footer>
      <Modal show={showModal} onHide={handleCloseModal} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Footerinner></Footerinner>

    </div>
  );



}

export default Editphoto;