import React from 'react'

function PremiumMatch() {
    return (
        <>
            <div className="pictures-client">
                <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center">
                <p style={{color:'#16183E', marginTop:'1rem'}}> No data found</p>
                </div>
            </div>
        </>
    )
}

export default PremiumMatch