import React from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalFooter, MDBBtn } from 'mdb-react-ui-kit';
import { IoIosCloseCircleOutline } from "react-icons/io";
const DeleteConfirmModel = ({ show, handleClose, handleDeleteConfirm, title, message }) => {
  return (
    <MDBModal show={show} onHide={handleClose}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader className='submit-register text-center'>
            <MDBModalTitle className='register-sucess-title mx-auto'>{title}</MDBModalTitle>
            <MDBBtn color='none' onClick={handleClose} className='custom-close-button' style={{backgroundColor:'#16183E'}}>&times;</MDBBtn>
          </MDBModalHeader>
          <div className='text-center '>
          <IoIosCloseCircleOutline  className='mt-2' style={{fontSize:'6rem',color:'red'}} />
          </div>
          <MDBModalFooter  className='d-flex justify-content-center' >
            <p>{message}</p>
           
          </MDBModalFooter>
          <div className='d-flex justify-content-center mb-4'>
          <MDBBtn color='danger'onClick={handleDeleteConfirm} className='delete-yes'>Yes</MDBBtn>
            <MDBBtn color='secondary' onClick={handleClose} className='delete-no'>No</MDBBtn> 
          </div>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default DeleteConfirmModel;
