// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBIFZO59giPyaM6EB5q3EVbSZ3qs8kKK3c",
  authDomain: "dmlf-matrimonyapp.firebaseapp.com",
  projectId: "dmlf-matrimonyapp",
  storageBucket: "dmlf-matrimonyapp.firebasestorage.app",
  messagingSenderId: "739660667530",
  appId: "1:739660667530:web:24c7199f48a2c521402b73",
  measurementId: "G-RWJMMW57HB"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const firebaseMessage = getMessaging(firebaseApp);
const generateToken = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notifications");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const firebaseMessage = getMessaging(firebaseApp);
      const token = await getToken(firebaseMessage, {
        vapidKey:
          "BAMfwNZHfXs-E136RzhwekMcFQiqkR09WC4VJ_--eg3TZvRcfUMIhHsCEZ52xZEdeimxAjcjArNmUrobJCLc9Sg",
      });
      return token;
    } else {
      console.error("Permission for notifications was not granted");
      return null;
    }
  } catch (error) {
    console.error(
      "Error occurred while requesting notification permission:",
      error
    );
    return null;
  }
};

export { firebaseApp, generateToken };
