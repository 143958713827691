import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import AllProfiles from "../AllProfiles";
import AgentAddNewProfileMain from "../AddProfile/Main";
import AgentMessageInboxList from "./MessageInboxList";
import Chat from "../../Chat";
import api from "../../../Api/Agent/AgentProfileApi";
import { toast } from "react-toastify";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { firebaseApp } from "../../../firebase/firebase";
import AgentFooter from "../AgentFooter";

const MessageInbox = ({ tab }) => {
  const firebaseDB = getFirestore(firebaseApp);
  const [selectedTab, setSelectedTab] = useState(tab ? tab : "Home");
  const token = localStorage.getItem("authToken");
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
    from: "",
  });
  const [userData, setUserData] = useState({
    name: "",
    image: "",
    profileId: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [selectedTab]);

  const handleBlockUser = () => {
    let payload = {
      otherProfileId: chatData?.userId,
    };
    api
      .blockAgentProfiles(userData?.profileId, payload, token)
      .then(({ data }) => {
        if (data.status) {
          setChatData({
            status: false,
            userId: "",
            name: "",
            image: "",
          });
          updateMessageInFirebase();
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const updateMessageInFirebase = async () => {
    const currentTimeInUTC = new Date().toUTCString();
    const combinedId =
      userData?.profileId > chatData?.userId
        ? `${chatData?.userId}-${userData?.profileId}`
        : `${userData?.profileId}-${chatData?.userId}`;
    await updateDoc(doc(firebaseDB, "lastMessages", combinedId), {
      status: "BLOCKED",
      time: currentTimeInUTC,
    });
  };
  return (
    <>
      <Helmet>
        <title>Agent-Messages | Dream Life Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <AgentNavbar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={() => {}}
        />
        <Container>
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={() => {}}
            />
            {selectedTab === "Home" && (
              <AgentMessageInboxList
                setChatData={setChatData}
                userData={userData}
                setUserData={setUserData}
              />
            )}
            {selectedTab === "AllProfiles" && <AllProfiles />}
            {selectedTab === "AddProfile" && <AgentAddNewProfileMain />}
          </MDBRow>
        </Container>
      </section>
      <AgentFooter />
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
              from: "",
            })
          }
          from={chatData?.from}
          isAgent={true}
          profileDetails={userData}
          onBlockUser={handleBlockUser}
        />
      )}
    </>
  );
};

export default MessageInbox;
