import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "../../../style/register.css";
import Footer from "../../Footer";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../style/datePicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../Api/AuthApi";
import agentAuthApi from "../../../Api/Agent/AgentAuth";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Header from "../Header";

const AgentRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formState = location?.state || {};
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [formData, setFormData] = useState({
    name: formState.name,
    mobile: formState.mobile,
    password: "",
    state: "",
    district: "",
    city: "",
  });
  useEffect(() => {
    return () => {
      fetchRegisterData();
    };
  }, []);

  const fetchRegisterData = async () => {
    await api.getRegister().then(({ data }) => {
      if (data) {
        setState(data?.stateDistrict);
      }
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    trigger(name);
    if (name === "district") {
      setFormData({ ...formData, district: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleInputDistrict = (type, value) => {
    console.log("type", type, value);
    setFormData({ ...formData, [type]: value });
  };

  //validation
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Please enter a valid email address")
      .email("Invalid email format")
      .matches(/\.com$|\.in$/, "Email must end with '.com' or '.in'"),
    password: yup
      .string()
      .min(8, "Password must have at least 8 characters")
      .max(20, "Password cannot exceed 20 characters")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?!.*[<>])\S*$/,
        "Password must contain letters and digits"
      )
      .required("Please enter password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Please enter confirm password")
      .required("'Confirm Password' field does not match the entered password"),
    state: yup.string().required("Please select your state"),
    district: yup.string().required("Please select your district"),
    city: yup.string().required("Please select your city"),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submitsection

  const [showModal, setShowModal] = useState();
  const [errorModal, setErrorModal] = useState();

  const submitForm = async (formValue) => {
    console.log("fomr", formSubmitted);
    if (formSubmitted) {
      return;
    }
    formValue.name = formData.name;
    formValue.mobile = formData.mobile;
    if (!formData.name) {
      setNameError("Please enter your name.");
    }

    if (!formData.mobile) {
      setMobileError("Please enter your mobile");
    }

    try {
      if (!nameError && !mobileError) {
        console.log("conffa", formValue);
        let payload = formValue;
        delete payload.confirmPassword;
        await agentAuthApi.register(payload).then(({ data }) => {
          if (data.status) {
            setFormSubmitted(true);
            setShowModal(true);
          } else {
            toast.error(data?.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
      // Navigate after showing alert
    } catch (error) {
      console.error("Error while submitting forms :", error);
      setFormSubmitted(false);
      setErrorModal(true);
      // alert('Unsuccessful');
    }
  };

  const handleCloseModal = () => {
    /*     setShowModal(false); */
    if (formSubmitted) {
      navigate("/agent/login");
    }
  };

  const handleerrorCloseModal = () => {
    setErrorModal(false);
    if (!formSubmitted) {
      setFormSubmitted(null);
      navigate("/agent/register");
    }
  };

  useEffect(() => {
    if (formData.state) {
      fetchDistricts();
      setCity([]);
      setFormData({ ...formData, district: "", city: "" });
    }
  }, [formData.state]);

  const fetchDistricts = async () => {
    const findDistricts = await state.find(
      (_state) => _state.id === formData.state
    );
    if (findDistricts) {
      setDistrict(findDistricts?.districts);
    }
  };

  useEffect(() => {
    if (formData.district) {
      fetchCity(formData.district);
      setFormData({ ...formData, city: "" });
    }
  }, [formData.district]);

  const fetchCity = async () => {
    const findCities = await district.find(
      (_district) => _district.dist_id == formData.district
    );
    if (findCities) {
      setCity(findCities?.cities);
    }
  };

  //confirm password//
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      const validateName = (value) => {
        const regex = /^[a-zA-Z\s]*$/; // Regular expression to match only letters and spaces
        return regex.test(value);
      };

      if (!validateName(value)) {
        setNameError("Name must contain only letters and spaces");
      } else {
        setNameError("");
      }
    } else {
      setNameError("Please enter your name.");
    }

    setFormData((prevValue) => ({
      ...prevValue,
      name: e.target.value,
    }));
  };

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      const validatePhoneNumber = (phoneNumber) => {
        const regex = /^[0-9]{10}$/; // Regular expression to match exactly 10 digits
        return regex.test(phoneNumber);
      };
      if (!validatePhoneNumber(value)) {
        setMobileError("Please enter a valid phone number.");
      } else {
        setMobileError("");
      }
    } else {
      setMobileError("Please enter your mobile.");
    }

    setFormData((prevValue) => ({
      ...prevValue,
      mobile: e.target.value,
    }));
  };

  const handleSubmitValidation = () => {
    if (!formData.name) {
      setNameError("Please enter your name.");
    }

    if (!formData.mobile) {
      setMobileError("Please enter your mobile");
    }
  };

  return (
    <>
      <Helmet>
        <title>Registration | Dream Life Matrimony</title>
      </Helmet>
      <ToastContainer />
      <div className="registerbody">
        <Header />
        <div className="registerbackground">
          <MDBContainer className="register mt-5 ">
            <div className="registerform">
              <div className="rgtr">
                <div className="position-relative">
                  <img
                    src="../Images/agent/register.png"
                    className="agent-banner position-relative"
                    alt="..."
                    style={{ width: "100%" }}
                  />
                  <img
                    src="../Images/agent/register-logo.png"
                    alt="register-logo"
                    className="agent-register-logo position-absolute top-50 start-50 translate-middle w"
                    style={{
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </div>
              </div>

              <h3 className="registerheading ">AGENT REGISTRATION</h3>

              <Form
                className="formregister"
                onSubmit={handleSubmit(submitForm)}
              >
                <MDBRow className="registerone">
                  <MDBCol size="6" sm="6" className="register-aligns">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Name *"
                        className="SelectBoxs   "
                        name="name"
                        value={formData.name}
                        style={{ width: 420 }}
                        onChange={handleNameInputChange}
                      />
                      {nameError && (
                        <label className="errormessage ">{nameError}</label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Email *"
                        className="SelectBoxs"
                        {...register("email")}
                        style={{ width: 420 }}
                        autoComplete="username"
                      />
                      {errors.email && (
                        <label className="errormessage">
                          {errors.email.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 d-none d-lg-block">
                      <select
                        id="district"
                        name="district"
                        {...register("district")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.district}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          District *
                        </option>
                        {district.map((district) => (
                          <option
                            key={district?.dist_id}
                            value={district?.dist_id}
                          >
                            {district.district}
                          </option>
                        ))}
                      </select>
                      {errors.district && (
                        <label className="errormessage">
                          {errors.district.message}
                        </label>
                      )}
                    </Form.Group>
                    <div className="mb-3 d-none d-lg-block">
                      <Form.Group
                        controlId="formBasicPassword"
                        style={{ position: "relative" }}
                      >
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Password *"
                          className="SelectBoxs"
                          onChange={(e) => {
                            setValue("password", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          style={{ width: 420 }}
                          autoComplete="new-password"
                        />

                        <span
                          className="password-toggle-icon"
                          onClick={handleTogglePassword}
                          style={{
                            position: "absolute",
                            top: "52%",
                            color: "#16183E",
                            right: 2,
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </Form.Group>
                      {errors.password && (
                        <label className="errormessage">
                          {errors.password.message}
                        </label>
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol size="6" sm="6" className="register-aligns">
                    <Form.Group className="mb-3" controlId="exampleForm.mobile">
                      <Form.Control
                        type="text"
                        placeholder="Mobile *"
                        className="SelectBoxs"
                        name="mobile"
                        value={formData?.mobile}
                        style={{ width: 420 }}
                        onChange={handleMobileInputChange}
                      />

                      {mobileError && (
                        <label className="errormessage">{mobileError}</label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <select
                        id="state"
                        name="state"
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        {...register("state")}
                        value={formData.state}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          State *
                        </option>
                        {state.map((state) => (
                          <option key={state?.id} value={state?.id}>
                            {state?.states}
                          </option>
                        ))}
                      </select>
                      {errors.state && (
                        <label className="errormessage">
                          {errors.state.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 d-lg-none">
                      <select
                        id="district"
                        name="district"
                        {...register("district")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.district}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          District *
                        </option>
                        {district.map((district) => (
                          <option
                            key={district?.dist_id}
                            value={district?.dist_id}
                          >
                            {district.district}
                          </option>
                        ))}
                      </select>
                      {errors.district && (
                        <label className="errormessage">
                          {errors.district.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <select
                        id="city"
                        name="city"
                        {...register("city")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.city}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          City *
                        </option>
                        {city.map((city) => (
                          <option key={city.city_id} value={city.city_id}>
                            {city.city}
                          </option>
                        ))}
                      </select>
                      {errors.city && (
                        <label className="errormessage">
                          {errors.city.message}
                        </label>
                      )}
                    </Form.Group>
                    <div className="mb-3 d-lg-none">
                      <Form.Group
                        controlId="formBasicPassword"
                        style={{ position: "relative" }}
                      >
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Password *"
                          className="SelectBoxs"
                          onChange={(e) => {
                            setValue("password", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          style={{ width: 420 }}
                          autoComplete="new-password"
                        />
                        <span
                          className="password-toggle-icon"
                          onClick={handleTogglePassword}
                          style={{
                            position: "absolute",
                            top: "52%",
                            color: "#16183E",
                            right: 2,
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </Form.Group>
                      {errors.password && (
                        <label className="errormessage">
                          {errors.password.message}
                        </label>
                      )}
                    </div>
                    <div className="mb-3">
                      <Form.Group
                        controlId="formConfirmPassword"
                        style={{ position: "relative" }}
                      >
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password *"
                          className="SelectBoxs"
                          {...register("confirmPassword", {
                            required:
                              "'Confirm Password' field does not match the entered password",
                            validate: (value) =>
                              value === getValues().password ||
                              "Passwords do not match. Please try again",
                          })}
                          style={{ width: 420 }}
                          autoComplete="new-password"
                          onChange={(e) => {
                            setValue("confirmPassword", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        <span
                          className="password-toggle-icon-confirmpassword"
                          onClick={handleToggleConfirmPassword}
                          style={{
                            position: "absolute",
                            top: "52%",
                            color: "#16183E",
                            right: 2,
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </Form.Group>

                      {errors.confirmPassword && (
                        <label className="errormessage">
                          {errors.confirmPassword.message}
                        </label>
                      )}
                    </div>
                  </MDBCol>

                  <div className="d-flex justify-content-center">
                    <Button
                      className="searchButtons"
                      type="submit"
                      onClick={handleSubmitValidation}
                    >
                      Submit
                    </Button>
                  </div>

                  {/* The first popup */}
                  <MDBModal show={showModal} onHide={handleCloseModal} centered>
                    <MDBModalDialog>
                      <MDBModalHeader
                        className="submit-register text-center"
                        style={{
                          borderBottom: "0px",
                          border: "10px",
                          marginTop: "10rem",
                        }}
                      >
                        {" "}
                        <MDBModalTitle className="register-sucess-title mx-auto">
                          <img
                            src="../Images/reg-success.png"
                            style={{
                              width: "25%",
                              marginBottom: "2rem",
                              marginTop: "1rem",
                            }}
                          ></img>
                          <br></br>
                          <p className="reg-success-message">
                            {" "}
                            Registration Successfully
                          </p>
                          <MDBBtn
                            color="none"
                            onClick={() => setShowModal(false)}
                            className="custom-close-buttons"
                          >
                            OK
                          </MDBBtn>
                        </MDBModalTitle>{" "}
                      </MDBModalHeader>
                    </MDBModalDialog>
                  </MDBModal>

                  {/* The error popup */}

                  <MDBModal
                    show={errorModal}
                    onHide={() => setErrorModal(false)}
                  >
                    <MDBModalDialog>
                      <MDBModalContent>
                        <MDBModalHeader className="submit-register text-center">
                          <MDBModalTitle className="register-sucess-title mx-auto">
                            Registration Unsuccessfully
                          </MDBModalTitle>
                          <MDBBtn
                            color="none"
                            onClick={handleerrorCloseModal}
                            className="custom-close-button"
                          >
                            &times;
                          </MDBBtn>
                        </MDBModalHeader>

                        <MDBModalFooter>
                          <p className="reg-footer-unsuccessfull">
                            {" "}
                            Phone number or Email is already Registered{" "}
                          </p>
                        </MDBModalFooter>
                      </MDBModalContent>
                    </MDBModalDialog>
                  </MDBModal>
                </MDBRow>
              </Form>
            </div>
          </MDBContainer>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
};

export default AgentRegistration;
