import React, { useEffect, useState } from "react";

import { MDBContainer } from "mdb-react-ui-kit";
import Footer from "./Footer";
import { Col, Row } from "react-bootstrap";
import "../style/upgrade.css";
import Nva from "./Nva";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import DMLApi from "../Api/UpdgradeDMLApi";

function Packeges() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      let { data } = await DMLApi.fetchPlans();
      if (data.status) {
        setPackages([...data?.data]);
      }
    } catch (err) {
      console.log("Error fetching plans", err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Packages | Dream Life Matrimony</title>
      </Helmet>
      <div className="registerbody">
        <Nva></Nva>

        <MDBContainer className=" mt-5 ">
          <div className="registerform">
            <div className="rgtr">
              <div className="bannr">
                <img
                  src="../Images/rtr.jpg"
                  className="img-fluid "
                  alt="..."
                  style={{ width: 100 }}
                />
              </div>
            </div>

            <div className="list-packages" style={{paddingTop: "2rem", paddingBottom: "2rem"}}>
              {packages.map((plan, index) => {
                const features = Object.values(plan.features).map(
                  (feature) => feature.description
                );

                return (
                  <div className="package" key={plan.id}>
                    <Row>
                      <Col>
                        <div className="package-left">
                          {features.map((feature, index) => (
                            <div className="package-line" key={index}>
                              <div
                                role="img"
                                aria-label="green tick"
                                className="check-image"
                              >
                                <img
                                  src="/Images/green-tick.png"
                                  alt="green tick"
                                />
                              </div>
                              {feature}
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col className="background-coupon">
                        <div className="background-coupon">
                          <img
                            src={plan?.image_url}
                            alt="coupon-bg"
                            className="background-coupon-image"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </div>
        </MDBContainer>

        <Footer />
      </div>
    </>
  );
}

export default Packeges;
