import React, { useEffect, useState } from "react";
import { TbClipboardList } from "react-icons/tb";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { RxChatBubble } from "react-icons/rx";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import Chat from "../Chat";
import api from "../../Api/ProfileApi";
import { ToastContainer, toast } from "react-toastify";
import ProfileApi from "../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const UserCardBottom = ({ user, hideProfile, chatData, setChatData }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem("authToken");
  const [isShortlisted, setIsShortListed] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const handleShortList = () => {
    api.shortListProfile(user?.profile_id, token).then(({ data }) => {
      if (data?.status) {
        setIsShortListed(!isShortlisted);
      }
    });
  };

  const handleHideProfile = () => {
    api.hideProfile(user?.profile_id, token).then(({ data }) => {
      if (data?.status) {
        hideProfile(user?.profile_id);
      }
    });
  };

  const handleLikeProfile = () => {
    api.likeProfile(user?.profile_id, token).then(({ data }) => {
      if (data?.status) {
        setIsLiked(!isLiked);
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          if (!data?.data?.isSubscribe) {
            navigate("/upgrade")
          }
        }, 1000)
      }
    });
  };

  useEffect(() => {
    setIsShortListed(user?.isShortlisted);
    setIsLiked(user?.isLiked);
  }, [user]);

  const handleChat = async () => {
    if (chatData?.status) {
      setChatData({
        status: false,
        userId: "",
        name: "",
        image: "",
      });
    } else {
      try {
        let response = await ProfileApi.checkChat(user?.profile_id, token);
        if (response.status) {
          if (response.data.data.isSubscribe) {
            setChatData({
              status: true,
              userId: user?.profile_id,
              name: user?.name,
              image: user?.profile_image,
            });
          } else {
            navigate("/upgrade");
          }
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  const [isAccept, setIsAccept] = useState("");

  useEffect(() => {
    setIsAccept(user.isAccepted);
  });

  return (
    <div className="match-bottom-container d-flex justify-content-between">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="match-bottom-link cursor-pointer" onClick={handleChat}>
        <p>Chat</p>
        <RxChatBubble className="mirror-icon match-bottom-icon" />
      </div>

      <div
        className="match-bottom-link cursor-pointer"
        onClick={handleShortList}
      >
        {isShortlisted ? (
          <>
            <p>Shortlisted</p>
            <HiClipboardDocumentCheck className="match-bottom-icon" />
          </>
        ) : (
          <>
            <p>Shortlist?</p>
            <TbClipboardList className="match-bottom-icon" />
          </>
        )}
      </div>

      <div
        className="match-bottom-link cursor-pointer hide-on-mobile"
        onClick={handleHideProfile}
      >
        <p>Hide?</p>
        <FaRegEyeSlash className="mirror-icon match-bottom-icon" />
      </div>

      <div
        className="match-bottom-link cursor-pointer  like-mobile"
        onClick={handleLikeProfile}
      >
        {isLiked ? (
          <>
            <p>Liked</p>
            <FaThumbsUp className="match-bottom-icon" />
          </>
        ) : (
          <>
            <p>Like His Profile?</p>
            <FaRegThumbsUp className="match-bottom-icon" />
          </>
        )}
      </div>
    </div>
  );
};

export default UserCardBottom;
