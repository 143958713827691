import React, { useEffect, useState } from "react";
import api from "../../../Api/Agent/AgentProfileApi";
import { MDBCol } from "mdb-react-ui-kit";
import Notification from "../../Notifications/Notification";
import ReactPaginate from "react-paginate";

const NotificatonList = () => {
  const token = localStorage.getItem("authToken");
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    getAllNotifications();
  }, [pageDetails]);

  const getAllNotifications = () => {
    setNotifications([]);
    const payload = {
      page: pageDetails?.page,
      pageSize: pageDetails?.limit,
    };
    api.getAgentNotifications(payload, token).then(({ data }) => {
      if (data.status) {
        setPagination(data?.data?.pagination);
        setNotifications(data.data);
      }
    });
  };
  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="notification_container">
        <h3 className="heading">Notifications</h3>

        <hr />

        <div className="notification_inner_container">
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Notification notification={notification} key={index} from="agent" />
            ))
          ) : (
            <div className="d-flex justify-content-center fs-5 text-secondary  align-items-center ">
              <p style={{ color: "#16183E", marginTop: "1rem" }}>
                No notifications for you
              </p>
            </div>
          )}
        </div>
        <div className="desktop-pagination">
          {pagination?.totalPages > 1 && (
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={
                  <i className="fa pagination-next fa-angle-left"></i>
                }
                nextLabel={
                  <i className="fa pagination-next fa-angle-right"></i>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pagination?.totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePagination}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"} // Define a CSS class for each page number element
                pageLinkClassName={"page-link"} // Define a CSS class for the page number link
              />
            </div>
          )}
        </div>
      </div>
    </MDBCol>
  );
};

export default NotificatonList;
