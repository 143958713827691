import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AgentNavbar from "../AgentNavbar";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar";
import AgentRightSide from "./AgentRightSide";
import AllProfiles from "../AllProfiles";
import AgentAddNewProfileMain from "../AddProfile/Main";
import AgentFooter from "../AgentFooter";

const AgentHome = ({ tab }) => {
  const [selectedTab, setSelectedTab] = useState(tab ? tab : "Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [selectedTab]);
  return (
    <>
      <Helmet>
        <title>Agent-Home | Dream Life Matrimony</title>
      </Helmet>
      <section className="matches-background">
        <AgentNavbar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
        <Container>
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
            {selectedTab === "Home" && <AgentRightSide />}
            {selectedTab === "AllProfiles" && (
              <AllProfiles setSelectedTab={setSelectedTab} />
            )}
            {selectedTab === "AddProfile" && <AgentAddNewProfileMain />}
          </MDBRow>
        </Container>
        <AgentFooter />
      </section>
    </>
  );
};

export default AgentHome;
