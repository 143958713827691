import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "../style/register.css";
import Footer from "./Footer";
import Nva from "./Nva";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "../style/datePicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../Api/AuthApi";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

function Registration() {
  const location = useLocation();
  const navigate = useNavigate();
  const formState = location?.state || {};
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [education, setEduccation] = useState([]);
  const [course, setCourse] = useState([]);
  const [employed_in, setEmployed_in] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [height, setHeight] = useState([]);
  const [fetchedProfileId, setFetchedProfileId] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [religion, setReligion] = useState([]);
  const [caste, setCaste] = useState([]);
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [formData, setFormData] = useState({
    name: formState.name,
    mobile: formState.mobile,
    looking_for: formState?.looking_for || "",
    password: "",
    state: "",
    district: "",
    city: "",
    gender:
      formState?.looking_for === "Bride"
        ? "Male"
        : formState?.looking_for === "Groom"
        ? "Female"
        : "",
    date_birth: "",
    age: "",
    any_disability: "",
    employed_in: "",
    occupation: "",
    religion: "",
    caste: "",
    profile_for: "",
    education: "",
    course: "",
  });
  //new gender groom//
  const [formValues, setFormValues] = useState({
    looking_for: formState?.looking_for || "",
    gender:
      formState?.looking_for === "Bride"
        ? "Male"
        : formState?.looking_for === "Groom"
        ? "Female"
        : "",
  });
  useEffect(() => {
    return () => {
      fetchRegisterData();
    };
  }, []);

  const fetchRegisterData = async () => {
    await api.getRegister().then(({ data }) => {
      if (data) {
        setEduccation(data?.education);
        setEmployed_in(data?.employed_in);
        setOccupation(data?.occupation);
        setState(data?.stateDistrict);
        setHeight(data?.heights);
        setReligion(data?.religionCaste);
      }
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    trigger(name);
    if (name === "district") {
      setFormData({ ...formData, district: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleInputDistrict = (type, value) => {
    setFormData({ ...formData, [type]: value });
  };

  //validation
  const schema = yup.object().shape({
    looking_for: yup.string().required("* This field is required"),
    email: yup
      .string()
      .required("Please enter a valid email address")
      .email("Invalid email format")
      .matches(/\.com$|\.in$/, "Email must end with '.com' or '.in'"),
    date_birth: yup.date().required("Date of Birth is required"),
    password: yup
      .string()
      .min(8, "Password must have at least 8 characters")
      .max(20, "Password cannot exceed 20 characters")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?!.*[<>])\S*$/,
        "Password must contain letters and digits"
      )
      .required("Please enter password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Please enter confirm password")
      .required("'Confirm Password' field does not match the entered password"),
    gender: yup.string().optional(),
    date_birth: yup.string().required("Date of birth is required"),
    age: yup.string(),
    height: yup.string().optional(),
    matrial_status: yup.string().optional(),
    any_disability: yup.string().optional(),
    education: yup.string().optional(),
    employed_in: yup.string().optional(),
    occupation: yup.string().optional(),
    // pincode :yup.string().optional(),
    state: yup.string().optional(),
    district: yup.string().optional(),
    city: yup.string().optional(),
    religion: yup.string().required("Please select a religion"),
    caste: yup.string().required("Caste is required"),
    profile_for: yup.string().optional(),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submitsection

  const [showModal, setShowModal] = useState();
  const [errorModal, setErrorModal] = useState();

  // DATE PICKER TESTING
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 90,
    today.getMonth(),
    today.getDate()
  );

  const [selectedDate, setSelectedDate] = useState(null); // State to manage selected date
  const [ageError, setAgeError] = useState(null);
  const [calculatedAge, setCalculatedAge] = React.useState(null);

  const calculateAge = (date) => {
    if (!date) {
      setCalculatedAge(null);
      return;
    }
    const now = new Date();
    const birthDate = new Date(date);
    const age = now.getFullYear() - birthDate.getFullYear();
    const monthDiff = now.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && now.getDate() < birthDate.getDate())
    ) {
      setCalculatedAge(age - 1);
    } else {
      setCalculatedAge(age);
    }

    if (age < 18 || age > 90) {
      setAgeError("Age must be between 18 and 90");
    } else {
      setAgeError(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    calculateAge(date);
  };

  const formatDate = (date) => {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day

    return `${year}-${month}-${day}`;
  };

  const getNumericAge = (ageString) => {
    if (!ageString) {
      return null;
    }
    const numericAge = parseInt(ageString, 10);
    return isNaN(numericAge) ? null : numericAge;
  };

  const initialDate = null;
  useEffect(() => {
    setValue("date_birth", initialDate);
  }, [setValue]);

  const submitForm = async (formValue) => {
    if (formSubmitted) {
      return;
    }
    formValue.date_birth = formatDate(selectedDate);
    const numericAge = getNumericAge(calculatedAge);
    formValue.age = numericAge;
    formValue.gender = formValues.gender;
    formValue.name = formData.name;
    formValue.mobile = formData.mobile;
    formValue.education_main = formData.education;
    formValue.education_sub = formData.course;
    if (!formData.name) {
      setNameError("Please enter your name.");
    }

    if (!formData.mobile) {
      setMobileError("Please enter your mobile");
    }

    try {
      if (!nameError && !mobileError) {
        await api.register(formValue).then(({ data }) => {
          if (data.status) {
            const newProfileId = data?.data?.profileId;
            setFetchedProfileId(newProfileId);
            setFormSubmitted(true);
            setShowModal(true);
            // navigate("/login");
          } else {
            toast.error(data?.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
      // Navigate after showing alert
    } catch (error) {
      console.error("Error while submitting forms :", error);
      setFormSubmitted(false);
      setErrorModal(true);
      // alert('Unsuccessful');
    }
  };

  const handleCloseModal = () => {
    /*     setShowModal(false); */
    if (formSubmitted) {
      navigate("/login");
    }
  };

  const handleerrorCloseModal = () => {
    setErrorModal(false);
    if (!formSubmitted) {
      setFormSubmitted(null);
      navigate("/registration");
    }
  };

  useEffect(() => {
    if (formData.state) {
      fetchDistricts();
      setCity([]);
      setFormData({ ...formData, district: "", city: "" });
    }
  }, [formData.state]);

  const fetchDistricts = async () => {
    const findDistricts = await state.find(
      (_state) => _state.id === formData.state
    );
    if (findDistricts) {
      setDistrict(findDistricts?.districts);
    }
  };

  useEffect(() => {
    if (formData.district) {
      fetchCity(formData.district);
      setFormData({ ...formData, city: "" });
    }
  }, [formData.district]);

  const fetchCity = async () => {
    const findCities = await district.find(
      (_district) => _district.dist_id == formData.district
    );
    if (findCities) {
      setCity(findCities?.cities);
    }
  };

  useEffect(() => {
    if (formData.religion) {
      fetchCaste();
    }
  }, [formData.religion]);

  const fetchCaste = async () => {
    const findReligion = await religion.find(
      (_religion) => _religion.id == formData.religion
    );
    if (findReligion) {
      setCaste(findReligion?.caste);
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      gender:
        name === "looking_for"
          ? value === "Bride"
            ? "Male"
            : value === "Groom"
            ? "Female"
            : ""
          : prevValues.gender,
    }));
  };
  //confirm password//
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      const validateName = (value) => {
        const regex = /^[a-zA-Z\s]*$/; // Regular expression to match only letters and spaces
        return regex.test(value);
      };

      if (!validateName(value)) {
        setNameError("Name must contain only letters and spaces");
      } else {
        setNameError("");
      }
    } else {
      setNameError("Please enter your name.");
    }

    setFormData((prevValue) => ({
      ...prevValue,
      name: e.target.value,
    }));
  };

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      const validatePhoneNumber = (phoneNumber) => {
        const regex = /^[0-9]{10}$/; // Regular expression to match exactly 10 digits
        return regex.test(phoneNumber);
      };
      if (!validatePhoneNumber(value)) {
        setMobileError("Please enter a valid phone number.");
      } else {
        setMobileError("");
      }
    } else {
      setMobileError("Please enter your mobile.");
    }

    setFormData((prevValue) => ({
      ...prevValue,
      mobile: e.target.value,
    }));
  };

  const handleSubmitValidation = () => {
    if (!formData.name) {
      setNameError("Please enter your name.");
    }

    if (!formData.mobile) {
      setMobileError("Please enter your mobile");
    }
  };

  const handleSelectEducation = (e) => {
    let educationId = e.target.value;
    const selectedEducation = education.find((educ) => educ.id == educationId);
    if (selectedEducation) {
      setCourse([...selectedEducation?.sub_qualification]);
    } else {
      setCourse([]);
    }
    setFormData((prevValue) => ({
      ...prevValue,
      education: educationId,
    }));
  };

  const handleSelectCourse = (e) => {
    let courseId = e.target.value;
    setFormData((prevValue) => ({
      ...prevValue,
      course: courseId,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Registration | Dream Life Matrimony</title>
      </Helmet>
      <ToastContainer />
      <div className="registerbody">
        <Nva></Nva>
        <div className="registerbackground">
          <MDBContainer className="register mt-5 ">
            <div className="registerform">
              <div className="rgtr">
                <div className="bannr">
                  <img
                    src="../Images/rtr.jpg"
                    className="img-fluid "
                    alt="..."
                    style={{ width: 100 }}
                  />
                </div>
              </div>

              <h3 className="registerheading ">Complete Profile</h3>

              <Form
                className="formregister"
                onSubmit={handleSubmit(submitForm)}
              >
                <MDBRow className="registerone">
                  <MDBCol size="6" sm="6" className="register-aligns">
                    <Form.Group className="mb-3">
                      <select
                        id="looking_for"
                        name="looking_for"
                        {...register("looking_for", { required: true })}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formValues.looking_for}
                        onChange={handleDropdownChange}
                      >
                        <option value="" disabled hidden>
                          Looking For*
                        </option>
                        <option value="Bride">Bride</option>
                        <option value="Groom">Groom</option>
                      </select>
                      {errors.looking_for && formValues.looking_for === "" && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Please select Bride or Groom
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="profile_for"
                        name="profile_for"
                        {...register("profile_for")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.profile_for}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected hidden>
                          Profile For
                        </option>
                        <option value="Myself">Myself</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Son">Son</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Relative">Relative</option>
                        <option value="Friend">Friend</option>
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Name *"
                        className="SelectBoxs   "
                        name="name"
                        value={formData.name}
                        style={{ width: 420 }}
                        onChange={handleNameInputChange}
                      />
                      {nameError && (
                        <label className="errormessage ">{nameError}</label>
                      )}
                    </Form.Group>
                    {/* </div> */}

                    <div className="mb-3">
                      <Form.Group>
                        <select
                          id="gender"
                          name="gender"
                          className="SelectBoxs-dropdown"
                          style={{ width: 420 }}
                          {...register("gender")}
                          value={formValues.gender}
                          onChange={handleDropdownChange}
                        >
                          <option value="" disabled>
                            Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </Form.Group>
                    </div>
                    <div className="mb-3">
                      <Form.Group className=" date_picker ">
                        <DatePicker
                          placeholderText="Date of Birth (yyyy-mm-dd)*"
                          name="date_birth"
                          {...register("date_birth", { required: true })}
                          style={{ width: 420 }}
                          selected={selectedDate}
                          onChange={(date) => {
                            handleDateChange(date);
                            setValue("date_birth", date); // Update the form field value
                          }}
                          dateFormat="yyyy-MM-dd"
                          maxDate={maxDate}
                          minDate={minDate}
                          showYearDropdown
                          yearDropdownItemNumber={90}
                        />
                      </Form.Group>
                      {errors.date_birth && !selectedDate && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Please select Date of Birth
                        </span>
                      )}
                    </div>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder={
                          ageError
                            ? "Age (Error: Age must be between 21 and 61)"
                            : "Age"
                        }
                        className={`SelectBoxs ${ageError ? "is-invalid" : ""}`}
                        name="age"
                        {...register("age")}
                        style={{ width: 420 }}
                        value={
                          calculatedAge !== null && !ageError
                            ? `${calculatedAge} years`
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="height"
                        name="height"
                        {...register("height")}
                        value={formData.height}
                        onChange={handleInputChange}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                      >
                        <option key="" value="" disabled selected hidden>
                          Height
                        </option>
                        {height.map((height) => (
                          <option key={height?.id} value={height?.id}>
                            {`${height?.height_cm} cm / ${height?.height_feet}`}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="matrial_status"
                        name="matrial_status"
                        {...register("matrial_status")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.matrial_status}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected hidden>
                          Marital Status
                        </option>
                        <option value="Never Married">Never Married</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Seperated">Seperated</option>
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.mobile">
                      <Form.Control
                        type="text"
                        placeholder="Phone *"
                        className="SelectBoxs"
                        name="mobile"
                        value={formData?.mobile}
                        style={{ width: 420 }}
                        onChange={handleMobileInputChange}
                      />

                      {mobileError && (
                        <label className="errormessage">{mobileError}</label>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Email *"
                        className="SelectBoxs"
                        {...register("email")}
                        style={{ width: 420 }}
                        autoComplete="username"
                      />
                      {errors.email && (
                        <label className="errormessage">
                          {errors.email.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <select
                        id="any_disability"
                        name="any_disability"
                        {...register("any_disability")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.any_disability} // Set the selected value based on formData
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected hidden>
                          Any Disability
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Form.Group>
                  </MDBCol>
                  <MDBCol size="6" sm="6" className="register-aligns">
                    <Form.Group className="mb-3">
                      <select
                        id="education"
                        value={formData.education}
                        onChange={handleSelectEducation}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                      >
                        <option key="" value="" disabled selected hidden>
                          Education/Qualification
                        </option>
                        {education.map((education) => (
                          <option key={education?.id} value={education?.id}>
                            {education?.main_qualification}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="course"
                        name="course"
                        value={formData.course}
                        onChange={handleSelectCourse}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                      >
                        <option key="" value="" disabled selected hidden>
                          Course
                        </option>
                        {course.map((cour) => (
                          <option key={cour?.id} value={cour?.id}>
                            {cour?.sub_qualification}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="employed_in"
                        name="employed_in"
                        {...register("employed_in")}
                        value={formData.employed_in}
                        onChange={handleInputChange}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                      >
                        <option key="" value="" disabled selected hidden>
                          Employed in
                        </option>
                        {employed_in.map((employed_in) => (
                          <option key={employed_in?.id} value={employed_in?.id}>
                            {employed_in?.employed_in}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="occupation"
                        name="occupation"
                        {...register("occupation")}
                        value={formData.occupation}
                        onChange={handleInputChange}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                      >
                        <option key="" value="" disabled selected hidden>
                          Occupation
                        </option>
                        {occupation.map((occupation) => (
                          <option key={occupation?.id} value={occupation?.id}>
                            {occupation?.occupation}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <select
                        id="state"
                        name="state"
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        {...register("state")}
                        value={formData.state}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          State
                        </option>
                        {state.map((state) => (
                          <option key={state?.id} value={state?.id}>
                            {state?.states}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="district"
                        name="district"
                        {...register("district")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.district}
                        onChange={(e) => {
                          handleInputDistrict("district", e.target.value);
                        }}
                      >
                        <option key="" value="" disabled selected hidden>
                          District
                        </option>
                        {district.map((district) => (
                          <option
                            key={district?.dist_id}
                            value={district?.dist_id}
                          >
                            {district.district}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="city"
                        name="city"
                        {...register("city")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.city}
                        onChange={handleInputChange}
                      >
                        <option key="" value="" disabled selected hidden>
                          City
                        </option>
                        {city.map((city) => (
                          <option key={city.city_id} value={city.city_id}>
                            {city.city}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="religion"
                        name="religion"
                        {...register("religion")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.religion}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected hidden>
                          Religion
                        </option>
                        {religion.map((religion) => (
                          <option key={religion.id} value={religion.id}>
                            {religion.religion}
                          </option>
                        ))}
                      </select>
                      {errors.religion && (
                        <label className="errormessage">
                          {errors.religion.message}
                        </label>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <select
                        id="caste"
                        name="caste"
                        {...register("caste")}
                        className="SelectBoxs-dropdown"
                        style={{ width: 420 }}
                        value={formData.caste}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled selected hidden>
                          Caste
                        </option>
                        {caste.map((caste) => (
                          <option key={caste.id} value={caste.id}>
                            {caste.caste}
                          </option>
                        ))}
                      </select>
                      {errors.caste && (
                        <label className="errormessage">
                          {errors.caste.message}
                        </label>
                      )}
                    </Form.Group>
                    <div className="mb-3">
                      <Form.Group
                        controlId="formBasicPassword"
                        style={{ position: "relative" }}
                      >
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password *"
                          className="SelectBoxs"
                          onChange={(e) => {
                            setValue("password", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          style={{ width: 420 }}
                          autoComplete="new-password"
                        />

                        <span
                          className="password-toggle-icon"
                          onClick={handleTogglePassword}
                          style={{
                            position: "absolute",
                            top: "52%",
                            color: "#16183E",
                            right: 2,
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </Form.Group>
                      {errors.password && (
                        <label className="errormessage">
                          {errors.password.message}
                        </label>
                      )}
                    </div>

                    <div className="mb-3">
                      <Form.Group
                        controlId="formConfirmPassword"
                        style={{ position: "relative" }}
                      >
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password *"
                          className="SelectBoxs"
                          {...register("confirmPassword", {
                            required:
                              "'Confirm Password' field does not match the entered password",
                            validate: (value) =>
                              value === getValues().password ||
                              "Passwords do not match. Please try again",
                          })}
                          style={{ width: 420 }}
                          autoComplete="new-password"
                          onChange={(e) => {
                            setValue("confirmPassword", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        <span
                          className="password-toggle-icon-confirmpassword"
                          onClick={handleToggleConfirmPassword}
                          style={{
                            position: "absolute",
                            top: "52%",
                            color: "#16183E",
                            right: 2,
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </Form.Group>

                      {errors.confirmPassword && (
                        <label className="errormessage">
                          {errors.confirmPassword.message}
                        </label>
                      )}
                    </div>
                  </MDBCol>

                  <div className="d-flex justify-content-center">
                    <Button
                      className="searchButtons"
                      type="submit"
                      onClick={handleSubmitValidation}
                    >
                      Submit
                    </Button>
                  </div>

                  {/* The first popup */}
                  <MDBModal show={showModal} onHide={handleCloseModal} centered>
                    <MDBModalDialog>
                      <MDBModalHeader
                        className="submit-register text-center"
                        style={{
                          borderBottom: "0px",
                          border: "10px",
                          marginTop: "10rem",
                        }}
                      >
                        {" "}
                        <MDBModalTitle className="register-sucess-title mx-auto">
                          <img
                            src="../Images/reg-success.png"
                            style={{
                              width: "25%",
                              marginBottom: "2rem",
                              marginTop: "1rem",
                            }}
                          ></img>
                          <br></br>
                          <p className="reg-success-message">
                            {" "}
                            Registration Successfully
                          </p>
                          <p className="reg-footer">
                            Your Profile ID: {fetchedProfileId}{" "}
                          </p>
                          <MDBBtn
                            color="none"
                            onClick={() => setShowModal(false)}
                            className="custom-close-buttons"
                          >
                            OK
                          </MDBBtn>
                        </MDBModalTitle>{" "}
                      </MDBModalHeader>
                    </MDBModalDialog>
                  </MDBModal>

                  {/* The error popup */}

                  <MDBModal
                    show={errorModal}
                    onHide={() => setErrorModal(false)}
                  >
                    <MDBModalDialog>
                      <MDBModalContent>
                        <MDBModalHeader className="submit-register text-center">
                          <MDBModalTitle className="register-sucess-title mx-auto">
                            Registration Unsuccessfully
                          </MDBModalTitle>
                          <MDBBtn
                            color="none"
                            onClick={handleerrorCloseModal}
                            className="custom-close-button"
                          >
                            &times;
                          </MDBBtn>
                        </MDBModalHeader>

                        <MDBModalFooter>
                          <p className="reg-footer-unsuccessfull">
                            {" "}
                            Phone number or Email is already Registered{" "}
                          </p>
                        </MDBModalFooter>
                      </MDBModalContent>
                    </MDBModalDialog>
                  </MDBModal>
                </MDBRow>
              </Form>
            </div>
          </MDBContainer>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}

export default Registration;
