import { axios, constants } from "../Config";

class SubscribeApiFetch {
  ApplyVoucher(payload, token) {
    return axios
      .post(`${constants.baseURL}subscribe/apply-voucher`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  ConfirmVoucher(payload, token) {
    return axios
      .post(`${constants.baseURL}subscribe/confirm`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occurred.");
      });
  }
  getCurrentPlan(token) {
    return axios
      .get(`${constants.baseURL}subscribe/current`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response) {
          return { data: error.response.data };
        }
        throw new error("An unexpected error occured.");
      });
  }
}

export default new SubscribeApiFetch();
