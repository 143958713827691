import React, { useState, useRef, useEffect } from 'react';
//import '../style/displaypage.css'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';




function Slidermatches() {
  const menuRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [menuScrollLeft, setMenuScrollLeft] = useState(0);
  //background//
  const location = useLocation();

  const handleMouseDown = (e) => {
    setIsDragging(true);
    menuRef.current.classList.add('active');
    setStartX(e.pageX - menuRef.current.offsetLeft);
    setMenuScrollLeft(menuRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    menuRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    menuRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - menuRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    menuRef.current.scrollLeft = menuScrollLeft - walk;
    if (menuRef.current.scrollLeft <= 0) {
      document.querySelector('.pointer.left-pointer').classList.add('dis');
      setMenuScrollLeft(menuRef.current.scrollLeft);
    } else {
      document.querySelector('.pointer.left-pointer').classList.remove('dis');
    }
    if (menuRef.current.scrollLeft + menuRef.current.offsetWidth >= menuRef.current.scrollWidth) {
      document.querySelector('.pointer.right-pointer').classList.add('dis');
    } else {
      document.querySelector('.pointer.right-pointer').classList.remove('dis');
    }
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);

  const scrollRight = () => {
    //menuRef.current.scrollLeft += 300; 

    menuRef.current.scrollBy(300, 0)
    console.log(menuRef.current)
  };

  const scrollLeft = () => {
    // menuRef.current.scrollLeft -= 300; 
    menuRef.current.scrollBy(-300, 0)
  };




  return (

    <div>
      <div className="menu-wrapper" >
        <div className="menu-item" ref={menuRef}>

          <Link to="/Matches" className={`matches-buttons ${location.pathname === '/Matches' ? 'active' : ''}`} >
            <Button type="button" as={Link} to="/Matches" >All Matches</Button>
          </Link>
          <Link to="/Newmatches" className={`matches-buttons ${location.pathname === '/Newmatches' ? 'active' : ''}`}>
            <Button type="button" as={Link} to="/Newmatches" >New Matches</Button>
          </Link>
          <Link to="/Premiummaches" className={`matches-buttons ${location.pathname === '/Premiummaches' ? 'active' : ''}`}>
            <Button type="button" as={Link} to="/Premiummaches" >Premium Matches</Button>
          </Link>
          <Link to="/Location" className={`matches-buttons ${location.pathname === '/Location' ? 'active' : ''}`}>
            <Button type="button" as={Link} to="/Location" >Location Matches</Button>
          </Link>
          <Link to="/Educationmatches" className={`matches-buttons ${location.pathname === '/Educationmatches' ? 'active' : ''}`}>
            <Button type="button" as={Link} to="/Educationmatches" >Education Matches</Button>

          </Link>

        </div>
        <span className="pointer left-pointer dis" onClick={scrollLeft}>
          <i className="fa fa-chevron-left"></i>
        </span>
        <span className="pointer right-pointer" onClick={scrollRight}>
          <i className="fa fa-chevron-right"></i>
        </span>
      </div>

    </div>
  )
}

export default Slidermatches